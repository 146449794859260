<template>
  <div v-if="testDetails" class="conduct-test">
    <div class="row h-100" style="gap: 20px">
      <div class="col s12 m12 l9 h-100">
        <div class="card question-card" style="height: 750px">
          <div class="question-header">
            <div class="flex flex-between items-center flex-wrap" style="gap: 20px">
              <p v-if="this.type === 4" class="fs-18 fw-600 dark-color">
                {{ testDetails.test.Description }}
              </p>
              <p v-else class="fs-18 fw-600 dark-color">
                Test - {{ testDetails.test.Description }}
              </p>
              <div v-if="this.type === 4">
                <img
                  v-if="currentQuestion.QuestionTypeId !== 2 && enablePause === true"
                  class="button-size"
                  :src="require(`../assets/pause-svg.svg`)"
                  @click="clickPauseAssignment()"
                />
                <img
                  class="button-size"
                  :src="require(`../assets/end-svg.svg`)"
                  @click="clickEndAssignment()"
                />
              </div>
              <div v-if="this.type !== 4">
                <button v-if="InstituteEndTest" @click="endTestModal = true" class="endTest-btn">
                  End Test
                </button>
                <button
                  v-if="InstituteSubjectiveTypeEndTest"
                  @click="endTestModal = true"
                  class="endTest-btn"
                >
                  End Test
                </button>
              </div>
            </div>
            <p class="question-num">{{ currentAllQuestionIndex }}</p>
            <div class="flex flex-between items-center flex-wrap" style="gap: 20px">
              <div>
                <p class="fs-16 dark-color" v-if="testDetails.type === 2">
                  <b>Section:</b> {{ currentQuestion.Part }}
                </p>
              </div>
              <div>
                <div class="flex items-center" style="gap: 5px" v-if="this.type !== 4">
                  <div>
                    <img
                      :src="require(`../assets/purple-timer-icon.svg`)"
                      alt=""
                      style="width: 25px; height: 25px"
                    />
                  </div>
                  <p class="fs-20 fw-600">
                    <span :class="{ timerRed: timeLeft < 300 }"
                      ><span v-if="time.hours < 10">0</span>{{ time.hours }}:<span
                        v-if="time.minutes < 10"
                        >0</span
                      >{{ time.minutes }}:<span v-if="time.seconds < 10">0</span
                      >{{ time.seconds }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="marks-per-questions-box" v-if="this.type !== 4">
              <p class="positive-marks" title="Marks Per Question">
                +{{ testDetails.questions[currentPartIndex][0].MarksPerQuestion || 0 }}
              </p>
              <p
                v-if="testDetails && testDetails.questions[currentPartIndex][0].NegativeMarks"
                title="Negative Marks Per Question"
                class="negative-marks"
              >
                -{{ testDetails.questions[currentPartIndex][0].NegativeMarks }}
              </p>
            </div>
          </div>
          <div>
            <div
              class="questions scroll-verticle"
              style="height: 385px; width: 93%"
              v-if="currentQuestion.QuestionTypeId !== 2"
            >
              <div
                v-if="currentQuestion.Q_LATEX_Status === 'Y' && currentQuestion.Q_LATEX"
                class="flex flex-column flex-between h-100"
              >
                <vue-mathjax
                  :formula="currentQuestion.Q_LATEX"
                  :options="VueMathJaxOptions"
                  :safe="false"
                  class="space-break fs-18 fw-500"
                />
                <div
                  v-if="currentQuestion.DiagramUrl"
                  :style="{
                    'min-width': `${getDiagramWidth(currentQuestion.DiagramUrl) * 720}px`,
                    'max-width': `${getDiagramWidth(currentQuestion.DiagramUrl) * 100}%`,
                  }"
                >
                  <img
                    class="col-sm-12 col-md-9 diagramURL"
                    :src="`${storage_Url}${getDiagramUrl(currentQuestion.DiagramUrl).replace(
                      '~',
                      '',
                    )}`"
                    alt=""
                    draggable="false"
                  />
                </div>

                <div
                  v-if="
                    currentQuestion.AnswerOption_A &&
                    currentQuestion.AnswerOption_B &&
                    currentQuestion.AnswerOption_C &&
                    currentQuestion.AnswerOption_D
                  "
                >
                  <button
                    class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                    :class="{
                      'green-bg darken-4 white-text border-none':
                        (currentQuestion.IsMarkedForReview &&
                          currentQuestion.ReviewAnswerOption.includes('A')) ||
                        currentQuestion.Answer.includes('A'),
                    }"
                    @click="selectAnswerOption('A')"
                  >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                      >check_box_outline_blank</i
                    >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                      >check_box</i
                    ><span v-if="!currentQuestion.AnswerOption_A.includes('(A)')">(A)</span>
                    <vue-mathjax
                      :formula="currentQuestion.AnswerOption_A"
                      :options="VueMathJaxOptions"
                      :safe="false"
                    />
                  </button>
                  <button
                    class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                    :class="{
                      'green-bg darken-4 white-text border-none':
                        (currentQuestion.IsMarkedForReview &&
                          currentQuestion.ReviewAnswerOption.includes('B')) ||
                        currentQuestion.Answer.includes('B'),
                    }"
                    @click="selectAnswerOption('B')"
                  >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                      >check_box_outline_blank</i
                    >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                      >check_box</i
                    ><span v-if="!currentQuestion.AnswerOption_B.includes('(B)')">(B)</span>
                    <vue-mathjax
                      :formula="currentQuestion.AnswerOption_B"
                      :options="VueMathJaxOptions"
                      :safe="false"
                    />
                  </button>
                  <button
                    class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                    :class="{
                      'green-bg darken-4 white-text border-none':
                        (currentQuestion.IsMarkedForReview &&
                          currentQuestion.ReviewAnswerOption.includes('C')) ||
                        currentQuestion.Answer.includes('C'),
                    }"
                    @click="selectAnswerOption('C')"
                  >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                      >check_box_outline_blank</i
                    >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                      >check_box</i
                    ><span v-if="!currentQuestion.AnswerOption_C.includes('(C)')">(C)</span>
                    <vue-mathjax
                      :formula="currentQuestion.AnswerOption_C"
                      :options="VueMathJaxOptions"
                      :safe="false"
                    />
                  </button>
                  <button
                    class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                    :class="{
                      'green-bg darken-4 white-text border-none':
                        (currentQuestion.IsMarkedForReview &&
                          currentQuestion.ReviewAnswerOption.includes('D')) ||
                        currentQuestion.Answer.includes('D'),
                    }"
                    @click="selectAnswerOption('D')"
                  >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                      >check_box_outline_blank</i
                    >
                    <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                      >check_box</i
                    ><span v-if="!currentQuestion.AnswerOption_D.includes('(D)')">(D)</span>
                    <vue-mathjax
                      :formula="currentQuestion.AnswerOption_D"
                      :options="VueMathJaxOptions"
                      :safe="false"
                    />
                  </button>
                </div>
              </div>
              <div v-else>
                <img
                  v-if="Number(this.type) !== 4"
                  :src="`${
                    storage_Url + '/' + currentQuestion.QuestionDiagramURL.replace('~/', '')
                  }`"
                  alt=""
                  style="
                    max-width: 700px;
                    max-height: 300px;
                    object-fit: contain;
                    display: block;
                    margin-top: 55px;
                  "
                  draggable="false"
                />
                <img
                  v-else
                  :src="`${currentQuestion.QuestionDiagramURL}`"
                  alt=""
                  style="
                    max-width: 700px;
                    max-height: 300px;
                    object-fit: contain;
                    display: block;
                    margin-top: 55px;
                  "
                  draggable="false"
                />
              </div>
            </div>
            <div
              class="questions scroll-verticle"
              style="max-height: 390px"
              v-if="currentQuestion.QuestionTypeId === 2"
            >
              <div v-if="InstituteTestSubTypeQuestion">
                <div
                  v-if="currentQuestion.Q_LATEX_Status === 'Y' && currentQuestion.Q_LATEX"
                  class="flex flex-column flex-between h-100"
                >
                  <vue-mathjax
                    :formula="currentQuestion.Q_LATEX"
                    :options="VueMathJaxOptions"
                    :safe="false"
                    class="space-break fs-18 fw-500"
                  />
                  <div
                    v-if="
                      currentQuestion.AnswerOption_A &&
                      currentQuestion.AnswerOption_B &&
                      currentQuestion.AnswerOption_C &&
                      currentQuestion.AnswerOption_D &&
                      currentQuestion.QuestionTypeId !== 8
                    "
                  >
                    <button
                      class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                      :class="{
                        'green-bg darken-4 white-text border-none':
                          currentQuestion.IsMarkedForReview
                            ? currentQuestion.ReviewAnswerOption.includes('A')
                            : currentQuestion.Answer.includes('A'),
                      }"
                      @click="selectAnswerOption('A')"
                    >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                        >radio_button_unchecked</i
                      >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                        >check_circle</i
                      ><span v-if="!currentQuestion.AnswerOption_A.includes('(A)')">(A)</span>
                      <vue-mathjax
                        :formula="currentQuestion.AnswerOption_A"
                        :options="VueMathJaxOptions"
                        :safe="false"
                      />
                    </button>
                    <button
                      class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                      :class="{
                        'green-bg darken-4 white-text border-none':
                          currentQuestion.IsMarkedForReview
                            ? currentQuestion.ReviewAnswerOption.includes('B')
                            : currentQuestion.Answer.includes('B'),
                      }"
                      @click="selectAnswerOption('B')"
                    >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                        >radio_button_unchecked</i
                      >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                        >check_circle</i
                      ><span v-if="!currentQuestion.AnswerOption_B.includes('(B)')">(B)</span>
                      <vue-mathjax
                        :formula="currentQuestion.AnswerOption_B"
                        :options="VueMathJaxOptions"
                        :safe="false"
                      />
                    </button>
                    <button
                      class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                      :class="{
                        'green-bg darken-4 white-text border-none':
                          currentQuestion.IsMarkedForReview
                            ? currentQuestion.ReviewAnswerOption.includes('C')
                            : currentQuestion.Answer.includes('C'),
                      }"
                      @click="selectAnswerOption('C')"
                    >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                        >radio_button_unchecked</i
                      >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                        >check_circle</i
                      ><span v-if="!currentQuestion.AnswerOption_C.includes('(C)')">(C)</span>
                      <vue-mathjax
                        :formula="currentQuestion.AnswerOption_C"
                        :options="VueMathJaxOptions"
                        :safe="false"
                      />
                    </button>
                    <button
                      class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                      :class="{
                        'green-bg darken-4 white-text border-none':
                          currentQuestion.IsMarkedForReview
                            ? currentQuestion.ReviewAnswerOption.includes('D')
                            : currentQuestion.Answer.includes('D'),
                      }"
                      @click="selectAnswerOption('D')"
                    >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                        >radio_button_unchecked</i
                      >
                      <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                        >check_circle</i
                      ><span v-if="!currentQuestion.AnswerOption_D.includes('(D)')">(D)</span>
                      <vue-mathjax
                        :formula="currentQuestion.AnswerOption_D"
                        :options="VueMathJaxOptions"
                        :safe="false"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                      :class="{
                        'green-bg darken-4 white-text border-none':
                          currentQuestion.IsMarkedForReview
                            ? currentQuestion.ReviewAnswerOption.includes('S')
                            : currentQuestion.Answer.includes('A'),
                      }"
                      @click="selectAnswerOption('A')"
                    >
                      <!-- <span v-if="!currentQuestion.AnswerOption_A.includes('(A)')">(A)</span> -->
                      <vue-mathjax :formula="True" :options="VueMathJaxOptions" :safe="false" />
                    </button>
                    <button
                      class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
                      :class="{
                        'green-bg darken-4 white-text border-none':
                          currentQuestion.IsMarkedForReview
                            ? currentQuestion.ReviewAnswerOption.includes('B')
                            : currentQuestion.Answer.includes('B'),
                      }"
                      @click="selectAnswerOption('B')"
                    >
                      <!-- <span v-if="!currentQuestion.AnswerOption_A.includes('(A)')">(A)</span> -->
                      <vue-mathjax :formula="False" :options="VueMathJaxOptions" :safe="false" />
                    </button>
                  </div>
                </div>
                <div v-else>
                  <img
                    v-if="this.type !== 4"
                    :src="`${
                      storage_Url + '/' + currentQuestion.QuestionDiagramURL.replace('~/', '')
                    }`"
                    alt=""
                    style="
                      max-width: 700px;
                      max-height: 300px;
                      object-fit: contain;
                      display: block;
                      margin-top: 55px;
                    "
                    draggable="false"
                  />
                  <img
                    :src="`${currentQuestion.QuestionDiagramURL}`"
                    alt=""
                    v-else
                    style="
                      max-width: 700px;
                      max-height: 300px;
                      object-fit: contain;
                      display: block;
                      margin-top: 55px;
                    "
                    draggable="false"
                  />
                </div>
              </div>

              <div v-if="InstTestQuestionSubmitLater" class="" style="margin-top: 50px">
                <div class="subjective-main-card">
                  <div class="internal-content" style="height: 390px; overflow: auto">
                    <table cellspacing="0" cellpadding="1" border="1">
                      <thead class="table-head">
                        <tr class="center-align">
                          <th>Question No</th>
                          <th>Upload Images</th>
                          <th>Action</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(data, index) in filteredSubjectiveQuestionList"
                        :key="data.QuestionId"
                      >
                        <tr>
                          <td>
                            {{ data.questionIndex }}.<a
                              style="text-decoration: underline; margin-left: 5px"
                              @click="ViewSubQuestion(data.QuestionDiagramURL)"
                            >
                              View Question Image</a
                            >
                          </td>
                          <td v-if="!SolutionUploadImage[index]">Please Upload Image</td>
                          <td v-if="SolutionUploadImage[index]" style="color: green">
                            Uploaded File
                          </td>
                          <!-- <td>{{this.SelectedImageUpload}}</td> -->
                          <td>
                            <label for="file1" style="font-size: 25px; color: #6d2d7e">
                              <input
                                id="file1"
                                type="file"
                                multiple
                                style="display: none"
                                accept="image/*"
                                @change="base64($event)"
                                v-if="openUploadFolder" />
                              <img
                                class="Uploadbox"
                                :src="require(`../assets/Uploadbox.png`)"
                                style="width: 32px; height: 32px; margin-right: 90px"
                                @click="
                                  SubjectiveQuestionImageId(
                                    data.questionIndex,
                                    data.QuestionId,
                                    data.PartSerialNumber - 1,
                                    index,
                                  )
                                "
                            /></label>
                            <!-- <img class="Vector" src="../assets/Vector.png" @click="ViewSubjcetiveUploadedImage(index)" /> -->
                          </td>
                          <td v-if="!SolutionUploadImage[index]" style="color: red">
                            <i class="material-icons">clear</i>
                          </td>
                          <td v-if="SolutionUploadImage[index]" style="color: green">
                            <i class="material-icons" style="color: green">check</i>
                          </td>
                          <!-- <td v-if="!SolutionUploadImage[index]" style="color: red"><i class="material-icons">clear</i></td>
                  <td v-if="SolutionUploadImage[index]" style="color: green">
                    <i class="material-icons" style="color: green">check</i>
                  </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-between items-center flex-wrap pX-10px"
            style="gap: 20px"
            v-if="currentQuestion.QuestionTypeId !== 2"
          >
            <input
              v-if="currentQuestion.QuestionTypeId === 4"
              v-model="currentQuestion.Answer"
              placeholder="enter your answer"
              type="text"
              class="browser-default"
              style="width: 200px"
              @keypress="onlyNumber"
              :maxlength="max"
            />
            <div class="options-btns flex" style="gap: 10px" v-if="isQuestionOptionVisible">
              <button
                class="option-btn"
                :class="{
                  'green-bg darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('A')) ||
                    currentQuestion.Answer.includes('A'),
                }"
                @click="selectAnswerOption('A')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck fs-18"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check fs-18"
                  >check_box</i
                >
                A
              </button>
              <button
                class="option-btn"
                :class="{
                  'green-bg darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('B')) ||
                    currentQuestion.Answer.includes('B'),
                }"
                @click="selectAnswerOption('B')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck fs-18"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check fs-18"
                  >check_box</i
                >
                B
              </button>
              <button
                class="option-btn"
                :class="{
                  'green-bg darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('C')) ||
                    currentQuestion.Answer.includes('C'),
                }"
                @click="selectAnswerOption('C')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck fs-18"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check fs-18"
                  >check_box</i
                >
                C
              </button>
              <button
                class="option-btn"
                :class="{
                  'green-bg darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('D')) ||
                    currentQuestion.Answer.includes('D'),
                }"
                @click="selectAnswerOption('D')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck fs-18"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check fs-18"
                  >check_box</i
                >
                D
              </button>
            </div>
            <div class="match-the-following" v-if="currentQuestion.QuestionTypeId === 7">
              <div
                class="match-the-following-item"
                v-for="(option, index) in optionsForMTF"
                :key="index"
              >
                <p>{{ option.value }}</p>
                <div class="flex items-center">
                  <button
                    @click="selectAnswerOption(option.value + '-1', index)"
                    :class="{
                      'green-bg darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(option.value + '-1')
                        : currentQuestion.Answer.includes(option.value + '-1'),
                    }"
                  >
                    1
                  </button>
                  <button
                    @click="selectAnswerOption(option.value + '-2', index)"
                    :class="{
                      'green-bg darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(option.value + '-2')
                        : currentQuestion.Answer.includes(option.value + '-2'),
                    }"
                  >
                    2
                  </button>
                  <button
                    @click="selectAnswerOption(option.value + '-3', index)"
                    :class="{
                      'green-bg darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(option.value + '-3')
                        : currentQuestion.Answer.includes(option.value + '-3'),
                    }"
                  >
                    3
                  </button>
                  <button
                    @click="selectAnswerOption(option.value + '-4', index)"
                    :class="{
                      'green-bg darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(option.value + '-4')
                        : currentQuestion.Answer.includes(option.value + '-4'),
                    }"
                  >
                    4
                  </button>
                  <div>
                    <img
                      :src="require(`../assets/eraser-icon.png`)"
                      alt=""
                      class="mtf-clear-single-img"
                      @click="clearSingleOption(option.value, index)"
                      v-if="currentQuestion.Answer.includes(option.value) || option.isSelected"
                    />
                    <!-- <span class="material-icons mtf-clear-single" @click="clearSingleOption(option.value, index)" v-if="currentQuestion.Answer.includes(option.value) || option.isSelected">close</span> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="options-btns flex"
              style="gap: 10px"
              v-if="currentQuestion.QuestionTypeId === 8"
            >
              <button
                class="option-btn"
                :class="{
                  'green-bg darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('A')) ||
                    currentQuestion.Answer.includes('A'),
                }"
                @click="selectAnswerOption('A')"
              >
                True
              </button>
              <button
                class="option-btn"
                :class="{
                  'green-bg darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('B')) ||
                    currentQuestion.Answer.includes('B'),
                }"
                @click="selectAnswerOption('B')"
              >
                False
              </button>
            </div>
          </div>
          <div v-if="currentQuestion.QuestionTypeId === 2">
            <!-- <div style="display: flex;justify-content: center;margin-top: 50px;">
                        <div v-for="(image, index) in images" :key="index">
                            <img class="selectedImage" :src="image" />
                        </div>
                    </div> -->
            <div
              v-if="InstituteTestSubTypeQuestion"
              class="flex items-center flex-between flex-wrap"
              style="gap: 10px"
            >
              <div class="left" style="color: red">
                <h6>Note:- You can upload a multiple answer files in the end</h6>
              </div>
              <!-- <div>
                <button class="submitNext-btn" @click="UploadNowSubjectQuestion()"
                  :disabled="(this.currentQuestionIndex != this.testDetails.questions[this.currentPartIndex].length)">Upload Now<i
                    class="material-icons">next_plan</i>
                </button>
              </div> -->
            </div>
            <div v-if="InstTestQuestionSubmitLater" class="flex items-center flex-between">
              <div class="left" style="color: green">
                <h6>Note:- Now You Can Submit</h6>
              </div>
              <div>
                <button
                  class="submitNext-btn"
                  :class="{ disable: !isAnswerUploaded }"
                  @click="InstituteTestSubjectQuestion()"
                >
                  Submit Now <i class="material-icons right">next_plan</i>
                </button>
              </div>
            </div>
          </div>
          <div
            class="features-btns flex flex-between items-center flex-wrap"
            style="gap: 20px; padding-top: 10px; position: relative"
            v-if="currentQuestion.QuestionTypeId !== 2"
          >
            <div
              class="flex flex-wrap-reverse"
              style="gap: 20px"
              :class="{ 'blur-class': this.revisionPopUp === true }"
            >
              <div
                class="saveLater-btn feature-btn"
                :class="{ disable: currentQuestion.IsAttempted === 1 }"
                v-if="this.type !== 4"
              >
                <input
                  type="checkbox"
                  id="saveForLater"
                  class="pointer"
                  :checked="currentQuestion.IsMarkedForReview === 1"
                  @change="saveForLater()"
                  name="saveForLater"
                />
                <label for="saveForLater" class="pointer fw-600 fs-15" style="color: #6b60cd"
                  >Save For Later</label
                >
              </div>
              <button
                class="clearAnswer-btn feature-btn"
                v-if="currentQuestion.IsAttempted === 1 || currentQuestion.Answer !== ''"
                @click="clearAnswerModal = true"
              >
                Clear Response
                <span class="material-icons">remove_circle</span>
              </button>
              <span
                v-if="currentQuestion.IsAttempted === 1"
                class="orange-text"
                style="margin: 10px 5px 0px 0px"
                >Question already attempted</span
              >
            </div>
            <div
              class="flex"
              style="gap: 20px"
              v-if="currentQuestion.QuestionTypeId !== 2"
              :class="{ 'blur-class': this.revisionPopUp === true }"
            >
              <button
                class="submitNext-btn"
                @click="prevQuestion()"
                :disabled="currentPartIndex === 0 && currentQuestionIndex === 0"
              >
                <span class="material-icons">chevron_left</span>
                Prev
              </button>
              <button
                v-if="this.type !== 4"
                class="submitNext-btn"
                :disabled="
                  currentPartIndex === testDetails.questions.length - 1 &&
                  currentQuestionIndex === testDetails.questions[currentPartIndex].length - 1
                "
                @click="submitNextFinal"
              >
                Next<span class="material-icons">chevron_right</span>
              </button>
              <button
                v-else
                class="submitNext-btn"
                :disabled="
                  currentPartIndex === testDetails.questions.length - 1 &&
                  currentQuestionIndex === testDetails.questions[currentPartIndex].length - 1
                "
                @click="submitAssignmentAnswer"
              >
                Next<span class="material-icons">chevron_right</span>
              </button>
              <button
                v-if="this.type === 4"
                class="option-button center-align"
                @click="showMenu()"
                style="background: #9642be"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="revisionPopUp" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="currentQuestion.examRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="currentQuestion.examRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="currentQuestion.MarkedForClarification === 0"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />Mark for Clarification
                </button>
                <button
                  v-if="currentQuestion.MarkedForClarification === 1"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                  @click="removeClarification()"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Remove
                  for Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="revisionPopUp = false">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex flex-between items-center Upload-Later"
            v-if="currentQuestion.QuestionTypeId === 2 && InstituteTestSubTypeQuestion"
          >
            <div
              class="ChangeProfile"
              style="line-height: 1.5px; cursor: pointer"
              @click="SubjectiveAttempted()"
            >
              <label style="font-size: 25px; color: #6d2d7e; cursor: pointer">
                <img class="UploadLaterIcon" :src="require(`../assets/cloud-plus.png`)" />
                <h6>Upload Later</h6>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col s12 m3 h-100 side-panel" :class="{ active: toggleSidePanel }">
        <div
          class="side-panel-button small-screen-only"
          @click="toggleSidePanel = !toggleSidePanel"
        >
          QUESTIONS
        </div>
        <a
          class="btn-floating btn-small small-screen-only"
          style="position: absolute; right: 20px; top: 20px"
          @click="toggleSidePanel = false"
          ><i class="material-icons">close</i></a
        >
        <div class="card test-stats box-shadow-none" style="height: 750px">
          <div>
            <p class="fs-18 fw-600" style="padding: 5px 0 15px 0">STATUS</p>
            <div class="status-info">
              <div class="status-card">
                <span class="status-icon" style="background-color: #f4f4f4; color: #333">{{
                  Number(testDetails.test.TotalQuestions) - Number(notVisited)
                }}</span
                >Not Visited
              </div>
              <div class="status-card">
                <span
                  v-if="this.type !== 4"
                  class="status-icon"
                  style="background-color: #ff4949; color: #fff"
                >
                  {{ notAttempted }}</span
                >
                <span
                  v-if="this.type === 4"
                  class="status-icon"
                  style="background-color: #ff4949; color: #fff"
                >
                  {{ notAttempted }}</span
                >
                Not Answered
              </div>
              <div class="status-card">
                <span class="status-icon green-bg">{{ submittedCount }}</span
                >Answered
              </div>
              <div class="status-card" v-if="this.type !== 4">
                <span class="status-icon orange-bg">{{ savedForLaterCount }}</span
                >Saved for Later
              </div>
              <div class="status-card">
                <span class="status-icon" style="background-color: #6b60cd; color: #fff">{{
                  currentAllQuestionIndex
                }}</span
                >Current Question
              </div>
            </div>
            <p class="fs-18 fw-600" style="padding: 25px 0 15px 0">QUESTION NAVIGATOR</p>
            <div class="scroll-verticle flex flex-column" style="max-height: 40vh; gap: 30px">
              <div
                v-for="(question, index) in testDetails.questions"
                :key="`q${index}`"
                class="flex flex-column"
              >
                <div
                  v-if="(testDetails.questions.length > 0 && testDetails.type === 2) || 3"
                  class="flex flex-between items-align flex-wrap"
                  style="padding-right: 5px"
                >
                  <p class="fw-600" style="padding-bottom: 10px">
                    {{ testDetails.questions[index][0].Part }}
                  </p>
                  <p style="color: gray">
                    Max Allowed - {{ testDetails.questions[index][0].AllowedAttempts }}
                  </p>
                </div>
                <div class="flex flex-wrap" style="gap: 20px; padding: 10px">
                  <span
                    v-for="(q, index2) in question"
                    :key="`q2${index2}`"
                    class="stats-option-btn"
                    :class="{
                      current:
                        q.PartSerialNumber === currentPartIndex + 1 &&
                        q.SerialNumber === currentQuestionIndex + 1,
                      'orange-bg darken-1 white-text noborder':
                        q.IsMarkedForReview && !q.IsAttempted,
                      'green-bg darken-4 white-text noborder': q.IsAttempted,
                      'blueBg-whiteColor':
                        Number(q.questionIndex) === Number(currentAllQuestionIndex),
                      'red-bg darken-1 white-text noborder':
                        q.isSkipped === 1 && q.IsMarkedForReview !== 1 && q.Answer === '',
                    }"
                    @click="gotoQuestion(index, index2)"
                  >
                    {{ q.questionIndex }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :show="endTestModal" @close="endTestModal = false" :showFooter="true">
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>End Test</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">Are you sure you want to end this test?</div>
        </div>
      </template>
      <template v-slot:footer>
        <a @click="endTestModal = false" class="btn-flat">Cancel</a>
        <button
          @click="endTest()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          END TEST
        </button>
      </template>
    </Modal>
    <Modal
      :show="leavePageModal"
      @close="
        leavePageModal = false;
        leaveTo = null;
      "
      :showFooter="true"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>Leave Page</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            Are you sure you want to leave this page? This will end this test?
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a
          @click="
            leavePageModal = false;
            leaveTo = null;
          "
          class="btn-flat"
          >Cancel</a
        >
        <button
          @click="endTest()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          OK
        </button>
      </template>
    </Modal>
    <Modal :show="clearAnswerModal" @close="clearAnswerModal = false" :showFooter="true">
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>Clear Answer</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            Are you sure you want to clear the answer submitted for this question?
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a @click="clearAnswerModal = false" class="btn-flat">Cancel</a>
        <button
          @click="clearAnswer()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
          v-if="this.type !== 4"
        >
          OK
        </button>
        <button
          @click="clearAssignmentAnswer()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
          v-else
        >
          OK
        </button>
      </template>
    </Modal>
    <Modal
      :show="ViewSubTypeQuestion"
      @close="ViewSubTypeQuestion = false"
      :showCloseBtn="false"
      width="700px"
    >
      <template v-slot:body>
        <table>
          <tr>
            <td class="center col s12">
              <img
                class="responsive-img"
                draggable="false"
                :src="`${this.SubjectiveQuestionImage}`"
              />
            </td>
          </tr>
        </table>
      </template>
    </Modal>

    <Modal
      :show="subjectiveToggleModal"
      @close="subjectiveToggleModal = false"
      :showFooter="false"
      width="500px"
    >
      <template v-slot:body>
        <div v-if="endtest">
          <div>
            <div class="row">
              <div class="col s12 center">
                <h5>Submit Answer</h5>
              </div>
            </div>
            <div>
              <div class="col s12 center">
                You have not submitted answer for some of the questions in the test. Do you still
                want to submit the answer ?
              </div>
              <div style="display: flex; justify-content: space-around; margin-top: 25px">
                <a @click="subjectiveToggleModal = false" class="btn-flat">Cancel</a>
                <button
                  @click="
                    allowSubmitSubjective = true;
                    InstituteTestSubjectQuestion();
                  "
                  class="waves-effect waves-red btn-flat materialize-red-text"
                  style="width: 100px"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <Modal>
            <template v-slot:footer class="center">
              <a @click="subjectiveToggleModal = false" class="btn-flat">Cancel</a>
              <button
                @click="endSubjectiveTest()"
                class="waves-effect waves-red btn-flat materialize-red-text"
                style="width: 100px"
              >
                END TEST
              </button>
            </template>
          </Modal>
        </div>
        <!-- <div v-if="SwitchToNextPart">
          <div>
            <div class="row">
              <div class="col s12 center">
                <h5>Submit Subjective part</h5>
              </div>
            </div>
            <div class="row">
              <div class="col s12 center"></div>
            </div>
            <div>
              <div class="col s12 center">You have not submitted images for some of the questions in the test . Do you
                still want to move to next part ?</div>
              <div style="display: flex;  justify-content: space-around;margin-top: 25px;">
                <a @click="subjectiveToggleModal = false" class="btn-flat">Cancel</a>
                <button @click="endSubjectiveTest()" class="waves-effect waves-red btn-flat materialize-red-text"
                  style="width: 100px;">Yes</button>
              </div>
            </div>
          </div>
        </div> -->
      </template>
    </Modal>
    <Modal2 :show="showModalPopUp" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <span class="fw-700 skyblue-text center" style="font-size: 20px">File size Alert</span>
          <p
            @click="showModalPopUp = false"
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <div class="hw-to-earn-content">
          <span class="dtitle" style="font-weight: 700; font-size: 20px"
            >The File size should be less than 1MB</span
          >
          <!-- <span class="dtitle"></span> -->
        </div>
      </template>
    </Modal2>
    <Modal :show="showAllQuestionDone" @close="showAllQuestionDone = false" :showFooter="true">
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>End Test</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            All the Questions have been attempted,If you want to submit test<br />
            Please click on end test.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a @click="showAllQuestionDone = false" class="btn-flat">Cancel</a>
        <button
          @click="endTest()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          END TEST
        </button>
      </template>
    </Modal>
    <Modal2
      :show="showAttemptedError"
      @close="
        showAttemptedError = false;
        showPartBtn = false;
      "
      :showHeader="false"
      :showCloseBtn="false"
    >
      <template v-slot:body>
        <div class="attempted-err relative">
          <p
            @click="
              showAttemptedError = false;
              showPartBtn = false;
            "
            style="position: absolute; right: 12px; cursor: pointer; top: 12px"
          >
            <span class="material-icons earn-close">close</span>
          </p>

          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <div class="flex flex-center text-center">
            <span
              class="mesage-body fs-16 fw-600 ln-17"
              style="margin-bottom: 25px; white-space: pre-line; width: 424px"
            >
              {{ errMsg }}
            </span>
          </div>
          <button class="cancel-button fs-13 fw-600 ln-18" @click="showAttemptedError = false">
            Ok
          </button>
          <!-- <div v-if="showPartBtn" class="part-btn-grp w-100">
                    <button class="part-btn" v-for="(item, index) in testDetails.questions" :key="index" @click="goToPart(index)">Part {{ index + 1 }}</button>
                  </div> -->
        </div>
      </template>
    </Modal2>
    <Modal2 :show="warningModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="reset-modal">
          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex flex-center fs-20 fw-600" style="margin-bottom: 8px"
            >Full-screen Mode Disabled
          </span>
          <div class="" style="padding-bottom: 23px">
            <span class="flex flex-center fs-13 fw-400"
              >Exiting full-screen mode or changing to a different tab/window while</span
            >
            <span class="flex flex-center fs-13 fw-400">
              the test is going on will result in the test ending automatically.
            </span>
          </div>
          <div class="flex flex-center">
            <button class="no-button fs-13 fw-600" @click="checkForFullScreen()">
              Return to FullScreen
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2
      :show="pauseModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="pauseModal = false"
    >
      <template v-slot:body>
        <div class="end-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/pausesvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Pause
          </span>
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >Are you sure you want to pause this Assignment?</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >You will be able to resume this Assignment again.</span
            >
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="pauseModal = false">
              CANCEL
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="pauseAssignment()">
              PAUSE
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="endModal" :showHeader="false" :showCloseBtn="false" @close="endModal = false">
      <template v-slot:body>
        <div class="end-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/end-session.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >End Assignment</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >Are you sure you want to end this Assignment?</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >You will not be able to resume this Assignment again.</span
            >
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="endModal = false">
              CANCEL
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endAssignment()">
              END NOW
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="noInternetConnetion" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="reset-modal">
          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex flex-center fs-20 fw-600" style="margin-bottom: 8px"
            >No Internet Connection
          </span>
          <div class="" style="padding-bottom: 23px">
            <span class="flex flex-center fs-13 fw-400"
              >Seems like you are disconnected from the Internet</span
            >
            <!-- <span class="d-flex justify-content-center fs-13 fw-400 ln-17"> the test is going on will result in the test ending automatically. </span> -->
          </div>
          <div class="flex flex-center">
            <button class="no-button fs-13 fw-600" @click="checkInternetConnection()">
              Resume Now
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <div>
      <ViewQuestionModalVue
        :questionId="currentQuestion.QuestionId"
        :correctAnswer="this.correctAnswer"
        :selectedTab="2"
        v-if="showQuestionData"
        @closeModal="closeToggle($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SWorker from "simple-web-worker";
// import {
//   TestTimer,
// } from "../worker-api";
import { useToast } from "vue-toastification";
import Mobileapi from "../Mobileapi";
import Modal2 from "../components/Model2.vue";
import Modal from "../components/Modal.vue";
import ViewQuestionModalVue from "../components/ViewQuestionModal.vue";

const toast = useToast();

export default {
  data() {
    return {
      // for latex and match the column
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
      optionsForMTF: [
        {
          value: "A",
          isSelected: false,
        },
        {
          value: "B",
          isSelected: false,
        },
        {
          value: "C",
          isSelected: false,
        },
        {
          value: "D",
          isSelected: false,
        },
      ],
      answerOptionsForMTF: ["P", "Q", "R", "S"],
      // for latex and MTC ends here
      showModalPopUp: false,
      images: [],
      fileList: [],
      fileLsit: "",
      clearAnswerModal: false,
      clientHeight: document.documentElement.clientHeight,
      testUserId: null,
      testId: null,
      baseUrl: null,
      type: null,
      // startTime: moment(),
      toggleModal: false,
      subjectiveToggleModal: false,
      endTestModal: false,
      leavePageModal: false,
      leaveTo: null,
      timeLeft: 0,
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timer: null,
      toggleSidePanel: false,

      testDetails: null,
      currentQuestionIndex: 0,
      currentPartIndex: 0,
      ssTestKey: "",
      instituteTestId: null,
      SLtoken: "",
      InstTestQuestionSubmitLater: false,
      InstituteTestSubTypeQuestion: true,
      //   SolutionImageUploaded: [],
      SolutionUploadImage: [],
      ViewSubTypeQuestion: false,
      SubjectiveQuestionImage: "",
      SubjectiveQuestionList: [],
      files: [],
      questionList: [],
      QuestionFile: [],
      QuestionIdList: [],
      subjectiveQuesList: [],
      questionSolutionList: [],
      fileListFinal: [],
      SelectedQuestionId: null,
      SubjectiveTypeQuestionIndex: "",
      serverTime: "",
      localTime: "",
      SubmittedInstituteSubjective: [],
      Base64File: null,
      QuestionIndex: null,
      SelectedImageName: "",
      InstituteEndTest: true,
      InstituteSubjectiveTypeEndTest: false,
      SubjectiveQuestionTypeId: null,
      SubjectiveQuestionIndexLength: null,
      SwitchToNextPart: false,
      endtest: true,
      InstituteTestCurrentQuestionIndex: null,
      InstituteTestCurrentPartIndex: null,
      SubjectId: null,
      currentsubjectiveQuestionId: "",
      timer1: null,
      showAllQuestionDone: false,
      attemptedQnsDetail: [],
      showAttemptedError: false,
      isSubmitTrigger: false,
      errMsg: "",
      showPartBtn: false,
      totalAttemptedQns: 0,
      attemptedQns: 0,
      matchTheFollowingAnswers: [],
      secnondTimer: 0,
      isQnsAttempted: false,
      countSubmitedSubjectiveAnswer: 0,
      openUploadFolder: true,
      allowSubmitSubjective: false,
      qnsStatusIndex: 0,
      totalSubjectiveQns: [],
      isAnswerUploaded: false,
      counter: 0,
      warningModal: false,
      gotoQuestionClicked: false,
      noInternetConnetion: false,
      revisionPopUp: false,
      isMarkForClarification: false,
      pauseModal: false,
      endModal: false,
      showQuestionData: false,
      currentQuestionTypeId: null,
      filteredSubjectiveQuestionList: [],
      subjectiveCounter: 0,
      userDetails: [],
      max: 10,
      isGoToClicked: false,
      correctAnswer: 0,
      newPartIndex: 0,
      newQuestionIndex: 0,
      assignmentQuestionType: 0,
      enablePause: true,
    };
  },
  mounted() {
    this.testUserId = parseInt(this.$route.params.testUserId);
    this.type = parseInt(this.$route.params.testType);
    this.enablePause =
      this.$route.params.enablePause !== undefined ? this.$route.params.enablePause : true;
    if (this.type !== 4) {
      window.addEventListener("fullscreenchange", (e) => {
        console.log(e);
        if (this.currentQuestion.QuestionTypeId !== 2) {
          this.counter += 1;
          if (this.counter % 2 === 0) {
            if (this.counter === 8) {
              this.endTest();
            } else {
              this.warningModal = true;
            }
          }
        }
      });

      let prevKey = "";
      window.addEventListener("keydown", (e) => {
        if (e.key === "F5") {
          e.preventDefault();
        } else if ((e.key === "W" || e.key === "w") && prevKey === "Control") {
          e.preventDefault();
          window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
          });
        } else if ((e.key === "R" || e.key === "r") && prevKey === "Control") {
          e.preventDefault();
          // this.warningModal = true;
          this.checkForFullScreen();
          window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
          });
        } else if (e.key.toUpperCase() === "F4" && (prevKey === "Alt" || prevKey === "Control")) {
          e.preventDefault();
          window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
          });
        } else if (e.key === "Tab" && prevKey === "Alt") {
          e.preventDefault();
          window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
          });
        } else if (e.key === "Escape" && prevKey === "Control") {
          e.preventDefault();
          window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
          });
        }
        prevKey = e.key;
      });
    }
  },
  activated() {
    // localStorage.removeItem("testQuestionList");
    if (localStorage && localStorage.getItem("SLToken")) {
      this.SLtoken = localStorage.getItem("SLToken") || "";
    }
    this.testUserId = parseInt(this.$route.params.testUserId);
    this.type = parseInt(this.$route.params.testType);
    this.instituteTestId = parseInt(this.$route.params.InstituteTestId);
    this.ssTestKey = `${this.testUserId}-${this.type}`;
    this.baseUrl = this.user.DomainName;
    if (this.type === 4) {
      Mobileapi.getExamQuestions({ ExamSessionId: this.testUserId }, (response) => {
        this.testDetails = response.data;
        if (this.testDetails !== null && Object.values(this.testDetails).length > 0) {
          let qnsIndex = 1;
          this.testDetails.questions.forEach((qnslist) => {
            qnslist.forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              element.questionIndex = qnsIndex;
              qnsIndex += 1;
              // eslint-disable-next-line no-param-reassign
              element.examRevisionQuestionId = null;
            });
          });
          const user = this.$store.getters.user;
          const apiData = { testId: this.testUserId, userId: user.UserId, testType: this.type };
          Mobileapi.GetInstituteTestSubjectiveQuestions(apiData, (rep) => {
            this.totalSubjectiveQns = rep.data;
            if (
              this.testDetails !== null &&
              Object.values(this.testDetails).length > 0 &&
              this.totalSubjectiveQns !== null &&
              this.totalSubjectiveQns.length > 0
            ) {
              this.simulateSubjectiveQuestionData();
            }
          });
        }
        this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      });
    } else {
      this.checkForFullScreen();
      Mobileapi.getServerTime({}, (resp) => {
        this.serverTime = resp.data.datetime;
        this.serverTime = moment(this.serverTime).add(1, "seconds");
        this.localTime = this.serverTime;
        // this.startTime = moment(this.serverTime);
        this.startServerTimer();
        if (this.type === 2) {
          this.$store.dispatch("showLoader", true);
          Mobileapi.startTest(
            {
              testUserId: this.testUserId,
              type: 2,
            },
            () => {
              this.getTest();
            },
            () => {
              this.$store.dispatch("showLoader", false);
            },
          );
        } else {
          this.getTest();
        }
      });
      const user = this.$store.getters.user;
      const apiData = { testId: this.testUserId, userId: user.UserId, testType: this.type };
      Mobileapi.GetInstituteTestSubjectiveQuestions(apiData, (rep) => {
        this.totalSubjectiveQns = rep.data;
        //   for (let i = 0; i < this.SubjectiveQuestionList.length; i += 1) {
        // this.SolutionUploadImage.push(false);
        // }
      });
      this.InstituteEndTest = true;
      this.InstituteSubjectiveTypeEndTest = false;
      this.SwitchToNextPart = false;
      this.endtest = true;
      this.triggerTimer();
      this.checkInternetConnection();
    }
  },
  deactivated() {
    clearTimeout(this.timer);
    this.testDetails = null;
    this.leavePageModal = false;
    this.leaveTo = null;
  },
  methods: {
    closeToggle() {
      this.showQuestionData = false;
      if (this.isGoToClicked === false) {
        this.currentQuestionIndex += 1;
      } else if (this.isGoToClicked === true) {
        this.isGoToClicked = false;
        this.currentPartIndex = this.newPartIndex;
        this.currentQuestionIndex = this.newQuestionIndex;
      }
      if (this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length) {
        this.currentQuestionIndex = this.testDetails.questions[this.currentPartIndex].length - 1;
        this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    showMenu() {
      this.revisionPopUp = true;
    },
    markForRevision() {
      const user = this.$store.getters.user;
      const data = {
        UserId: user.UserId,
        SourceTypeId: 1,
        QuestionId: this.currentQuestion.QuestionId,
        ExamSessionQuestionId: this.currentQuestion.ExamSessionQuestionId,
        CreatedBy: user.UserId,
        ExamSessionId: this.testUserId,
        isFromWeb: 1,
      };
      Mobileapi.addExamRevisionQuestion(data, (response) => {
        const revisionQuestionId = response.data[0].ExamRevisionQuestionId;
        this.currentQuestion.examRevisionQuestionId = revisionQuestionId;
      });
    },
    removeRevision() {
      const data = {
        ExamRevisionQuestionId: this.currentQuestion.examRevisionQuestionId,
      };
      Mobileapi.removeExamRevisionQuestion(data, (response) => {
        const removedQuestion = response.data;
        this.currentQuestion.examRevisionQuestionId = null;
        console.log(removedQuestion);
      });
    },
    markQuesForClarification() {
      const data = {
        AnswerOption: null,
        QuestionId: this.currentQuestion.QuestionId,
        isAttempted: 0,
        isCorrectlyAnswered: 0,
        ExamSessionId: this.testUserId,
        StartedOn: this.currentQuestion.StartedOn,
      };
      Mobileapi.markForClarification(data, (response) => {
        this.questionMarked = response.data;
        if (this.currentQuestion.MarkedForClarification === 1) {
          this.currentQuestion.MarkedForClarification = 0;
        } else {
          this.currentQuestion.MarkedForClarification = 1;
        }
      });
    },
    removeClarification() {
      this.markQuesForClarification();
    },
    pauseAssignment() {
      this.$store.dispatch("showLoader", true);
      localStorage.removeItem("testQuestionList");
      Mobileapi.pauseExam(
        {
          ExamSessionId: Number(this.testUserId),
        },
        (response) => {
          if (response.responseCode === 200) {
            this.pauseModal = false;
            this.$router.push({
              path: "/Homework",
            });
          }
        },
      );
      this.$store.dispatch("showLoader", false);
    },
    endAssignment() {
      this.$store.dispatch("showLoader", true);
      localStorage.removeItem("testQuestionList");
      Mobileapi.endExam(
        {
          ExamSessionId: this.testUserId,
        },
        (response) => {
          if (response.responseCode === 200) {
            this.endModal = false;
            if (this.assignmentQuestionType !== 2) {
              this.$router.push({
                name: "TestReport",
                params: {
                  testUserId: Number(this.testUserId),
                  testType: Number(this.type),
                },
              });
            } else {
              toast.info("Report will be avaible once the Assignment is Evaluated");
              this.$router.push({
                path: "/HomeWork",
              });
            }
          }
        },
      );
      this.$store.dispatch("showLoader", false);
    },
    clickEndAssignment() {
      this.endModal = true;
      if (this.currentQuestion.Answer !== "") {
        this.submitAssignmentAnswer();
      }
    },
    clickPauseAssignment() {
      this.pauseModal = true;
      this.submitAssignmentAnswer();
    },
    // anti-cheating methods
    checkForFullScreen() {
      const isInFullScreen =
        (document.fullScreenElement && document.fullScreenElement !== null) || // alternative standard method
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      const docElm = document.documentElement;
      if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        }
      }
      this.warningModal = false;
    },
    disableFullScreen() {
      const isInFullScreen =
        (document.fullScreenElement && document.fullScreenElement !== null) || // alternative standard method
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      const docElm = document.documentElement;
      if (isInFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozExitFullScreen) {
          document.mozExitFullScreen();
        } else if (document.webkitExitFullscreen) {
          docElm.webkitExitFullscreen();
        }
      }
      this.warningModal = false;
    },
    detectFocusOut() {
      if (this.InstTestQuestionSubmitLater === false) {
        let inView = false;
        const onWindowFocusChange = (e) => {
          if ({ focus: 1, pageshow: 1 }[e.type]) {
            if (inView) return;
            this.tabFocus = true;
            inView = true;
            // this.warningModal = false;
          } else if (inView) {
            this.tabFocus = !this.tabFocus;
            inView = false;
            if (this.type !== 4 || this.currentQuestion.QuestionTypeId !== 2) {
              this.warningModal = true;
            }
          }
        };
        window.addEventListener("focus", onWindowFocusChange);
        window.addEventListener("blur", onWindowFocusChange);
        window.addEventListener("pageshow", onWindowFocusChange);
        window.addEventListener("pagehide", onWindowFocusChange);
      }
    },
    checkInternetConnection() {
      if (window.navigator.onLine) {
        this.noInternetConnetion = false;
      } else {
        this.noInternetConnetion = true;
      }
    },

    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    submitNextFinal() {
      if (this.type !== 4) {
        this.checkInternetConnection();
        if (this.currentQuestion.Answer === "" || this.currentQuestion.IsMarkedForReview) {
          if (this.currentQuestion.IsMarkedForReview !== 1) {
            this.currentQuestion.isSkipped = 1;
          }
          this.nextQuestion();
        } else {
          this.isSubmitTrigger = true;
          this.submitFinalAnswer(0);
        }
      }
    },
    getDiagramUrl(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.url;
    },
    getDiagramWidth(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.position[2];
    },
    getDiagramHeight(diagramDetail) {
      const diagram = JSON.parse(diagramDetail);
      return diagram.diagram_0.position[3];
    },
    clearSingleOption(value, index) {
      this.optionsForMTF[index].isSelected = false;
      this.matchTheFollowingAnswers = this.matchTheFollowingAnswers.filter(
        (item) => !item.includes(value),
      );
      if (this.matchTheFollowingAnswers.length === 0) {
        this.clearAnswer();
      } else {
        this.currentQuestion.Answer = this.matchTheFollowingAnswers.toString();
      }
    },
    clearAssignmentAnswer() {
      const data = {
        AnswerOption: null,
        QuestionId: this.currentQuestion.QuestionId,
        isAttempted: 0,
        isCorrectlyAnswered: 0,
        ExamSessionId: this.testUserId,
        StartedOn: this.currentQuestion.StartedOn,
      };
      Mobileapi.submitExamAnswerNew(data, (response) => {
        const output = response.data;
        console.log(output);
        this.clearAnswerModal = false;
        this.currentQuestion.IsAttempted = 0;
        this.currentQuestion.Answer = "";
      });
    },
    submitAssignmentAnswer(index, index2) {
      if (this.currentQuestion.Answer !== "") {
        this.currentQuestion.IsAttempted = 1;
        this.currentQuestion.isSkipped = 0;
      } else {
        this.currentQuestion.IsAttempted = 0;
        this.currentQuestion.isSkipped = 1;
      }
      const data = {
        AnswerOption: this.currentQuestion.Answer,
        QuestionId: this.currentQuestion.QuestionId,
        isAttempted: this.currentQuestion.IsAttempted,
        isCorrectlyAnswered:
          this.currentQuestion.Answer === this.currentQuestion.CorrectAnswer ? 1 : 0,
        ExamSessionId: this.testUserId,
        StartedOn: this.currentQuestion.StartedOn,
      };
      Mobileapi.submitExamAnswerNew(data, (response) => {
        const output = response.data[0];
        this.currentQuestion.ExamSessionQuestionId = output.ExamSessionQuestionId;
        if (this.currentQuestion.Answer === this.currentQuestion.CorrectAnswer) {
          this.correctAnswer = 1;
        } else {
          this.correctAnswer = 0;
        }
        if (this.currentQuestion.Answer !== "") {
          this.showQuestionData = true;
        }
        if (this.currentQuestion.Answer === "") {
          if (this.isGoToClicked === false) {
            this.currentQuestionIndex += 1;
          } else if (this.isGoToClicked === true) {
            this.isGoToClicked = false;
            this.currentPartIndex = index;
            this.currentQuestionIndex = index2;
          }
        }

        this.currentPartIndex = 0;
        if (
          this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length
        ) {
          this.currentQuestionIndex = this.testDetails.questions[this.currentPartIndex].length - 1;
          this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        }
      });
    },
    clearAnswer() {
      this.checkInternetConnection();
      this.clearAnswerModal = false;
      if (this.currentQuestion.IsAttempted !== 1 && this.currentQuestion.Answer !== "") {
        this.currentQuestion.Answer = "";
        if (this.currentQuestion.IsMarkedForReview === 1) {
          this.currentQuestion.ReviewAnswerOption = "";
        }
      } else {
        const data = {
          testId: this.testDetails.test.Id,
          type: this.type,
          serialNumber: this.currentQuestion.SerialNumber,
          partSerialNumber: this.currentQuestion.PartSerialNumber,
          testUserId: this.currentQuestion.TestUserId,
          answerOption: this.currentQuestion.Answer,
          startedOn: this.currentQuestion.StartedOn,
          review: 3,
          isFromWeb: 1,
        };
        this.$store.dispatch("showLoader", true);

        Mobileapi.submitAnswer(
          data,
          (response) => {
            this.$store.dispatch("showLoader", false);
            if (response.errors.length) {
              toast.error(`${response.errors[0]}`, {
                timeout: 2000,
              });
            } else {
              this.attemptedQnsDetail = this.attemptedQnsDetail.map((item, index) => {
                if (index === this.currentPartIndex) {
                  return { ...item, QnsAttempted: item.QnsAttempted - 1 };
                }
                return item;
              });
              this.attemptedQns = this.attemptedQnsDetail.reduce(
                (accu, curr) => accu + Number(curr.QnsAttempted),
                0,
              );
              this.currentQuestion.Answer = "";
              this.matchTheFollowingAnswers = [];
              this.optionsForMTF.forEach((e) => {
                e.isSelected = false;
              });
              this.currentQuestion.IsAttempted = 0;
              this.saveToSS();
              // this.serverTime = response.date[0];
              // this.serverTime = moment(this.serverTime).add(1, "seconds");
              // this.startTime = moment(this.serverTime);
            }
            this.updateLocalStorage();
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      }
    },
    getTest() {
      this.$store.dispatch("showLoader", true);
      this.checkInternetConnection();
      Mobileapi.getInsitituteTestQuestions(
        {
          testUserId: this.testUserId,
          type: this.type,
        },
        (response) => {
          this.testDetails = response;
          let qnsIndex = 1;
          this.testDetails.questions.forEach((qnslist) => {
            qnslist.forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              element.questionIndex = qnsIndex;
              qnsIndex += 1;
              // eslint-disable-next-line no-param-reassign
              element.isSkipped = 0;
            });
          });
          if (this.totalSubjectiveQns.length > 0) {
            this.totalSubjectiveQns.forEach((subjectiveQns) => {
              this.testDetails.questions = this.testDetails.questions.map((qnsList) =>
                qnsList.map((qns) => {
                  if (
                    subjectiveQns.questionId === qns.QuestionId &&
                    subjectiveQns.solutionUrlList.length > 0
                  ) {
                    return { ...qns, IsAttempted: 1, IsCompleted: 1 };
                  }
                  return qns;
                }),
              );
              this.SubjectiveQuestionList = this.SubjectiveQuestionList.map((subQns) => {
                if (
                  subQns.QuestionId === subjectiveQns.questionId &&
                  subjectiveQns.solutionUrlList.length > 0
                ) {
                  return { ...subQns, IsAttempted: 1, IsCompleted: 1 };
                }
                return subQns;
              });
            });
          }
          if (
            localStorage.getItem("testQuestionList") === null ||
            localStorage.getItem("testQuestionList") === null
          ) {
            localStorage.setItem("testQuestionList", JSON.stringify(this.testDetails.questions));
          } else {
            this.testDetails.questions = JSON.parse(localStorage.getItem("testQuestionList"));
          }
          if (Object.keys(this.testDetails.questions).length > 0) {
            this.testDetails.questions.forEach((item1) => {
              this.attemptedQnsDetail.push({
                QnsAttempted: item1.reduce(
                  (accu, currVal) => accu + Number(currVal.IsAttempted),
                  0,
                ),
                AllowedAttempts: item1[0].AllowedAttempts,
                TotalQuestions: item1.length,
              });
              this.totalAttemptedQns += item1[0].AllowedAttempts;
              item1.forEach((qns) => {
                if (qns.QuestionTypeId === 2) {
                  this.SubjectiveQuestionList.push(qns);
                  if (qns.IsAttempted === 1) {
                    this.SolutionUploadImage.push(true);
                  } else {
                    this.SolutionUploadImage.push(false);
                  }
                }
              });
            });
            this.attemptedQns = this.attemptedQnsDetail.reduce(
              (accu, curr) => accu + Number(curr.QnsAttempted),
              0,
            );
          }

          if (this.currentQuestion.QuestionTypeId === 2) {
            this.SubjectId =
              this.testDetails.questions[this.currentPartIndex][
                this.currentQuestionIndex
              ].SubjectId;
          }

          // if (this.testDetails.questions[this.currentPartIndex].length < this.testDetails.test.TotalQuestions && this.currentQuestion.QuestionTypeId === 2) {
          //   this.SwitchToNextPart = true;
          //   this.endtest = false;
          // } else {
          //   this.SwitchToNextPart = false;
          //   this.endtest = true;
          // }
          if (this.testDetails.status === "Completed") {
            toast.error("Test already ended", {
              timeout: 2000,
            });
            setTimeout(() => {
              window.location.href = `${this.baseUrl}/SelectTestHistory.aspx`;
            }, 2000);
          } else {
            this.$store.dispatch("showLoader", false);
          }
          if (window.localStorage[this.ssTestKey]) {
            const td = JSON.parse(window.localStorage[this.ssTestKey]);
            this.testDetails.questions = td.questions;
          }
          this.currentQuestionIndex = parseInt(window.localStorage[`${this.ssTestKey}-cqi`]) || 0;
          this.currentPartIndex = parseInt(window.localStorage[`${this.ssTestKey}-cpi`]) || 0;
          this.getCurrrentServerTime();
          this.matchTheFollowingAnswers =
            this.currentQuestion.Answer.split(",").filter((value) => value) || [];
          localStorage[`${this.ssTestKey}-et`] = localStorage[`${this.ssTestKey}-et`]
            ? localStorage[`${this.ssTestKey}-et`]
            : this.serverTime + this.testDetails.test.DurationAvailableInMins * 60 * 1000;
          this.timeLeft = Math.ceil((localStorage[`${this.ssTestKey}-et`] - this.localTime) / 1000);
          this.testDetails.questions[this.currentPartIndex][
            this.currentQuestionIndex
          ].isSelected = true;
          this.startTimer();
          // }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    simulateSubjectiveQuestionData() {
      if (this.totalSubjectiveQns.length > 0) {
        this.totalSubjectiveQns.forEach((subjectiveQns) => {
          this.testDetails.questions = this.testDetails.questions.map((qnsList) =>
            qnsList.map((qns) => {
              this.assignmentQuestionType = qns.QuestionTypeId;
              if (
                subjectiveQns.questionId === qns.QuestionId &&
                subjectiveQns.solutionUrlList.length > 0
              ) {
                return { ...qns, IsAttempted: 1, IsCompleted: 1 };
              }
              return qns;
            }),
          );
          this.SubjectiveQuestionList = this.SubjectiveQuestionList.map((subQns) => {
            if (
              subQns.QuestionId === subjectiveQns.questionId &&
              subjectiveQns.solutionUrlList.length > 0
            ) {
              return { ...subQns, IsAttempted: 1, IsCompleted: 1 };
            }
            return subQns;
          });
        });
      }

      if (Object.keys(this.testDetails.questions).length > 0) {
        this.testDetails.questions.forEach((item1) => {
          this.attemptedQnsDetail.push({
            QnsAttempted: item1.reduce((accu, currVal) => accu + Number(currVal.IsAttempted), 0),
            AllowedAttempts: item1[0].AllowedAttempts,
            TotalQuestions: item1.length,
          });
          this.totalAttemptedQns += item1[0].AllowedAttempts;
          item1.forEach((qns) => {
            if (qns.QuestionTypeId === 2) {
              this.SubjectiveQuestionList.push(qns);
              if (qns.IsAttempted === 1) {
                this.SolutionUploadImage.push(true);
              } else {
                this.SolutionUploadImage.push(false);
              }
            }
          });
        });
        this.attemptedQns = this.attemptedQnsDetail.reduce(
          (accu, curr) => accu + Number(curr.QnsAttempted),
          0,
        );
      }
    },
    ViewSubQuestion(QuestionImg) {
      this.SubjectiveQuestionImage = `${this.storage_Url}/${QuestionImg}`;
      this.ViewSubTypeQuestion = true;
    },
    replaceByFirstCharacter(arr, char, newStr) {
      return arr.map((e) => (e[0] === char ? newStr : e));
    },
    selectAnswerOption(option, index) {
      if (this.currentQuestion.QuestionTypeId === 1 || this.currentQuestion.QuestionTypeId === 5) {
        this.currentQuestion.Answer = option;
      }
      if (this.currentQuestion.QuestionTypeId === 8) {
        this.currentQuestion.Answer = option;
      }
      if (this.currentQuestion.QuestionTypeId === 7) {
        this.optionsForMTF[index].isSelected = true;
        const replacedArray = this.replaceByFirstCharacter(
          this.matchTheFollowingAnswers,
          option.charAt(0),
          option,
        );
        this.matchTheFollowingAnswers = replacedArray.includes(option)
          ? replacedArray
          : [...this.matchTheFollowingAnswers, option];
        this.currentQuestion.Answer = this.matchTheFollowingAnswers.toString();
      }
      if (this.currentQuestion.QuestionTypeId === 3) {
        if (this.currentQuestion.Answer.includes(option)) {
          this.currentQuestion.Answer = this.currentQuestion.Answer.replace(option, "");
        } else {
          this.currentQuestion.Answer = `${this.currentQuestion.Answer}${option}`;
        }
        this.currentQuestion.Answer = this.currentQuestion.Answer.split("")
          .sort((a, b) => a.localeCompare(b))
          .join("");
      }

      if (this.currentQuestion.IsMarkedForReview) {
        this.currentQuestion.ReviewAnswerOption = this.currentQuestion.Answer;
      }
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.timeLeft = Math.ceil((localStorage[`${this.ssTestKey}-et`] - this.localTime) / 1000);
        if (this.timeLeft > 0) {
          this.timeLeft -= 1;
          this.time.hours = Math.floor(this.timeLeft / 3600);
          this.time.minutes = Math.floor((this.timeLeft - this.time.hours * 3600) / 60);
          this.time.seconds = Math.floor(
            this.timeLeft - this.time.hours * 3600 - this.time.minutes * 60,
          );
          this.startTimer();
        } else if (this.testDetails.status !== "Completed") {
          toast.error("Time completed. Test will be submitted.", {
            timeout: 2000,
          });
          this.endTest();
        }
      }, 1000);
    },
    triggerTimer() {
      // console.log("triggerTimer function called");
      this.secnondTimer = setTimeout(() => {
        Mobileapi.GetServerTime({}, (resp) => {
          this.serverTime = resp.currentTime;
          this.serverTime = moment(this.serverTime).add(1, "seconds");
          this.localTime = this.serverTime;
          // this.startTime = moment(this.serverTime);
        });
        this.triggerTimer();
      }, 10000);
    },
    startServerTimer() {
      // console.log("startServerTimer function called");
      SWorker.run((arg) => `Hello, ${arg}!`, ["World"])
        .then(() => {
          // console.log(result);
          this.timer1 = window.setInterval(() => {
            // console.log("startServerTimer setInterval");
            // this.serverTime = moment(this.serverTime).add(1, "seconds");
            this.localTime = moment(this.localTime).add(1, "seconds");
            // if (this.currentQuestion.QuestionTypeId === 2) {
            //   this.disableFullScreen();
            // } else {
            //   this.detectFocusOut();
            //   this.checkForFullScreen();
            // }
            // if (this.currentQuestion.QuestionTypeId !== 2) {
            //   this.detectFocusOut();
            // }
          }, 1000);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    saveToSS() {
      window.localStorage[`${this.ssTestKey}-cpi`] = this.currentPartIndex;
      window.localStorage[`${this.ssTestKey}-cqi`] = this.currentQuestionIndex;
    },
    saveForLater() {
      this.checkInternetConnection();
      if (this.currentQuestion.IsMarkedForReview === 0) {
        this.isSubmitTrigger = false;
        this.currentQuestion.IsMarkedForReview = 1;
        this.currentQuestion.ReviewAnswerOption = this.currentQuestion.Answer;
        this.updateLocalStorage();
        this.saveToSS();
        this.submitAnswer(1);
      } else {
        // this.isSubmitTrigger = false;
        this.currentQuestion.IsMarkedForReview = 0;
        this.currentQuestion.ReviewAnswerOption = "";
        this.currentQuestion.Answer = "";
        const data = {
          testId: this.testDetails.test.Id,
          type: this.type,
          serialNumber: this.currentQuestion.SerialNumber,
          partSerialNumber: this.currentQuestion.PartSerialNumber,
          testUserId: this.currentQuestion.TestUserId,
          answerOption: this.currentQuestion.Answer,
          startedOn: this.currentQuestion.StartedOn,
          review: 0,
          isFromWeb: 1,
        };
        this.$store.dispatch("showLoader", true);
        Mobileapi.submitAnswer(
          data,
          (response) => {
            this.$store.dispatch("showLoader", false);
            this.updateLocalStorage();
            if (response.errors.length) {
              toast.error(`${response.errors[0]}`, {
                timeout: 2000,
              });
            } else {
              // this.serverTime = response.date[0];
              // this.serverTime = moment(this.serverTime).add(1, "seconds");
              // this.startTime = moment(this.serverTime);
              this.getCurrrentServerTime();
              this.saveToSS();
            }
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      }
    },
    InstituteTestsaveForLater() {
      this.currentQuestion.IsMarkedForReview = 1;
      this.currentQuestion.ReviewAnswerOption = this.currentQuestion.Answer;
      this.saveToSS();
      this.submitAnswer(1);
    },
    prevQuestion() {
      if (this.type !== 4) {
        this.checkInternetConnection();
        if (
          this.currentQuestion.IsAttempted !== 1 &&
          this.currentQuestion.IsMarkedForReview !== 1
        ) {
          this.currentQuestion.Answer = "";
        }
        this.isSubmitTrigger = false;
        this.submitAnswer(2, () => {
          if (this.currentQuestion.Answer !== "" || this.currentQuestion.IsMarkedForReview !== 1) {
            this.currentQuestion.isSkipped = 1;
          }
          if (this.currentQuestionIndex === 0) {
            this.currentPartIndex -= 1;
            this.currentQuestionIndex =
              this.testDetails.questions[this.currentPartIndex].length - 1;
          } else {
            this.currentQuestionIndex -= 1;
          }
          this.matchTheFollowingAnswers = this.currentQuestion.Answer.split(",");
          this.optionsForMTF.forEach((e) => {
            e.isSelected = false;
          });
          this.getCurrrentServerTime();
          this.saveToSS();
        });
      } else if (this.type === 4) {
        if (this.currentQuestionIndex === 0) {
          this.currentPartIndex -= 1;
          this.currentQuestionIndex = this.testDetails.questions[this.currentPartIndex].length - 1;
        } else {
          this.currentQuestionIndex -= 1;
        }
        this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    getCurrrentServerTime() {
      Mobileapi.GetServerTime({}, (resp) => {
        this.serverTime = resp.currentTime;
        this.serverTime = moment(this.serverTime);
        this.localTime = this.serverTime;
        // this.startTime = moment(this.serverTime);
        this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
      });
    },
    nextQuestion() {
      this.checkInternetConnection();
      this.getCurrrentServerTime();
      this.optionsForMTF.forEach((e) => {
        e.isSelected = false;
      });
      if (this.currentQuestion.IsAttempted !== 1 && this.currentQuestion.IsMarkedForReview !== 1) {
        this.currentQuestion.Answer = "";
        this.currentQuestion.isSkipped = 1;
        this.matchTheFollowingAnswers = this.currentQuestion.Answer.split(",");
        this.optionsForMTF.forEach((e) => {
          e.isSelected = false;
        });
        this.updateLocalStorage();
      }
      this.submitAnswer(2, () => {
        if (
          this.currentQuestionIndex <
          this.testDetails.questions[this.currentPartIndex].length - 1
        ) {
          this.currentQuestionIndex += 1;
          this.matchTheFollowingAnswers = this.currentQuestion.Answer.split(",");
          this.optionsForMTF.forEach((e) => {
            e.isSelected = false;
          });
          // this.getCurrrentServerTime();
          // this.saveToSS();
        } else if (
          this.currentQuestionIndex ===
            this.testDetails.questions[this.currentPartIndex].length - 1 &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.currentQuestionIndex = 0;
          this.currentPartIndex += 1;
          this.SolutionUploadImage = [];
          this.matchTheFollowingAnswers = this.currentQuestion.Answer.split(",");
          this.optionsForMTF.forEach((e) => {
            e.isSelected = false;
          });
          // this.getCurrrentServerTime();
          // this.saveToSS();
        }
      });
      if (this.currentQuestion.QuestionTypeId === 2) {
        this.disableFullScreen();
      } else {
        this.checkForFullScreen();
      }
    },
    nextFinalQuestion() {
      this.checkInternetConnection();
      this.optionsForMTF.forEach((e) => {
        e.isSelected = false;
      });
      this.submitFinalAnswer(2, () => {
        if (
          this.currentQuestionIndex <
          this.testDetails.questions[this.currentPartIndex].length - 1
        ) {
          this.currentQuestionIndex += 1;
          // this.getCurrrentServerTime();
          // this.saveToSS();
        } else if (
          this.currentQuestionIndex ===
            this.testDetails.questions[this.currentPartIndex].length - 1 &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.currentQuestionIndex = 0;
          this.currentPartIndex += 1;
          this.SolutionUploadImage = [];
          // this.getCurrrentServerTime();
          // this.saveToSS();
        }
        this.matchTheFollowingAnswers = this.currentQuestion.Answer.split(",");
        this.optionsForMTF.forEach((e) => {
          e.isSelected = false;
        });
        if (this.currentQuestion.QuestionTypeId === 2) {
          this.disableFullScreen();
          this.warningModal = false;
        } else {
          this.checkForFullScreen();
        }
      });
    },
    SubjectiveAttempted() {
      if (this.type !== 4) {
        this.InstitutenextQuestion();
      } else {
        this.ExamNextQuestion();
      }
      this.getCurrrentServerTime();
      this.saveToSS();
    },
    ExamNextQuestion() {
      if (this.currentQuestion.IsAttempted === 0) {
        this.currentQuestion.isSkipped = 1;
      }
      if (
        this.currentQuestionIndex <
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.currentQuestionIndex += 1;
        this.getCurrrentServerTime();
        this.saveToSS();
      } else if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.uploadNowAssignmentQuestion();
        toast.success("Upload the answer to complete the Assignment", {
          timeout: 2000,
        });
      }
    },
    InstitutenextQuestion() {
      this.checkInternetConnection();
      if (this.currentQuestion.IsAttempted === 0) {
        this.currentQuestion.isSkipped = 1;
      }
      this.updateLocalStorage();
      if (
        this.currentQuestionIndex <
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.currentQuestionIndex += 1;
        this.getCurrrentServerTime();
        this.saveToSS();
      } else if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.UploadNowSubjectQuestion();
        toast.info("Please upload the Answers", {
          timeout: 2000,
        });
        if (
          this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.UploadNowSubjectQuestion();
        }
      } else if (this.submittedCount === this.testDetails.test.TotalQuestions) {
        this.UploadNowSubjectQuestion();
      }
      this.InstituteTestCurrentQuestionIndex = this.currentQuestionIndex;
      this.InstituteTestCurrentPartIndex = this.currentQuestion.QuestionTypeId;
      this.SubjectId = this.currentQuestion.SubjectId;
      this.currentQuestionIndex = this.InstituteTestCurrentQuestionIndex;
      if (this.InstTestQuestionSubmitLater === true) {
        this.currentsubjectiveQuestionId =
          this.testDetails.questions[this.currentPartIndex].length - 1;
      }
      if (this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length) {
        this.UploadNowSubjectQuestion();
        toast.error("Please upload the Answers", {
          timeout: 2000,
        });
        if (
          this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.UploadNowSubjectQuestion();
        }
      } else if (this.submittedCount === this.testDetails.test.TotalQuestions) {
        this.UploadNowSubjectQuestion();
      }
      if (this.currentQuestion.QuestionTypeId === 2) {
        this.disableFullScreen();
        this.warningModal = false;
      } else {
        this.checkForFullScreen();
      }
    },
    updateLocalStorage() {
      this.testDetails.questions[this.currentPartIndex][this.currentQuestionIndex] =
        this.currentQuestion;
      localStorage.setItem("testQuestionList", JSON.stringify(this.testDetails.questions));
      this.testDetails.questions = JSON.parse(localStorage.getItem("testQuestionList"));
    },
    gotoQuestion(index, index2) {
      if (this.type !== 4) {
        this.checkInternetConnection();
        this.isSubmitTrigger = false;
        if (this.currentQuestion.Answer === "") {
          this.submitAnswer(2, () => {
            if (this.currentQuestion.QuestionTypeId === 2) {
              if (this.currentQuestion.IsAttempted === 0) {
                this.currentQuestion.isSkipped = 1;
              }
            } else {
              this.currentQuestion.isSkipped = 1;
            }
            if (this.currentPartIndex !== index) {
              this.InstTestQuestionSubmitLater = false;
              this.InstituteTestSubTypeQuestion = true;
              this.SolutionUploadImage = [];
            }
            this.updateLocalStorage();
            this.currentPartIndex = index;
            this.currentQuestionIndex = index2;
            this.getCurrrentServerTime();
            this.toggleSidePanel = !this.toggleSidePanel;
            this.saveToSS();
            if (this.currentQuestion.QuestionTypeId === 2) {
              this.disableFullScreen();
              this.warningModal = false;
            } else {
              this.checkForFullScreen();
            }
          });
        } else {
          this.gotoQuestionClicked = true;
          this.isSubmitTrigger = true;
          if (
            this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
              this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
            this.currentQuestion.IsAttempted !== 1
          ) {
            this.showAttemptedError = true;
            this.errMsg =
              "You have completed all required questions, Please clear answer of any question to submit this answer.";
          } else {
            this.submitFinalAnswer(0, () => {
              if (this.currentPartIndex !== index) {
                this.InstTestQuestionSubmitLater = false;
                this.InstituteTestSubTypeQuestion = true;
                this.SolutionUploadImage = [];
              }
              this.currentQuestion.IsAttempted = 1;
              this.updateLocalStorage();
              this.currentPartIndex = index;
              this.currentQuestionIndex = index2;
              this.getCurrrentServerTime();
              this.toggleSidePanel = !this.toggleSidePanel;
              this.saveToSS();
              if (this.currentQuestion.QuestionTypeId === 2) {
                this.disableFullScreen();
                this.warningModal = false;
              } else {
                this.checkForFullScreen();
              }
            });
          }
        }
      } else {
        this.isGoToClicked = true;
        this.newPartIndex = index;
        this.newQuestionIndex = index2;
        if (this.currentQuestion.QuestionTypeId !== 2) {
          this.submitAssignmentAnswer(index, index2);
        } else {
          this.currentPartIndex = index;
          this.currentQuestionIndex = index2;
        }
        this.currentQuestion.StartedOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        this.toggleSidePanel = !this.toggleSidePanel;
      }

      // if (this.InstituteTestCurrentPartIndex === 2 && (this.currentPartIndex < this.testDetails.questions.length - 1)) {
      //   this.currentQuestionIndex = this.InstituteTestCurrentQuestionIndex;
      // } else {
      //   this.currentQuestionIndex = index2;
      // }
    },
    submitAnswer(review, cb) {
      this.checkInternetConnection();
      // if (!review && this.currentQuestion.Answer === "") {
      //   window.M.toast({
      //     html: "Please select an answer before submission",
      //   });
      //   return;
      // }
      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        serialNumber: this.currentQuestion.SerialNumber,
        partSerialNumber: this.currentQuestion.PartSerialNumber,
        testUserId: this.currentQuestion.TestUserId,
        answerOption: this.currentQuestion.Answer,
        startedOn: this.currentQuestion.StartedOn,
        review,
        isFromWeb: 1,
      };
      this.$store.dispatch("showLoader", true);
      Mobileapi.submitAnswer(
        data,
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.errors.length) {
            toast.error(`${response.errors[0]}`, {
              timeout: 2000,
            });
          } else {
            // this.serverTime = response.date[0];
            // this.serverTime = moment(this.serverTime).add(1, "seconds");
            // this.startTime = moment(this.serverTime);
            if (!review) {
              this.currentQuestion.IsAttempted = 1;
              this.currentQuestion.IsMarkedForReview = 0;
              this.nextQuestion();
              if (this.submittedCount === this.testDetails.test.TotalQuestions) {
                this.showAllQuestionDone = true;
                // this.endTest();
              }
              // if (this.SubjectiveQuestionTypeId === 2) {
              //   if (this.submittedCount === this.testDetails.test.TotalQuestions - 1) {
              //     this.endTest();
              //   }
              // }
            }
            this.getCurrrentServerTime();
            this.saveToSS();
            if (cb) {
              cb();
            }
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    submitFinalAnswer(review, cb) {
      // console.log("submitFinalAnswer function called");
      this.checkInternetConnection();
      if (!review && this.currentQuestion.Answer === "") {
        toast.error("Please select an answer before submission", {
          timeout: 2000,
        });
        return;
      }
      if (this.currentQuestion.IsAttempted === 1 && review === 0) {
        this.isQnsAttempted = true;
      } else if (this.currentQuestion.IsAttempted !== 1 && review === 0) {
        this.isQnsAttempted = false;
      }
      if (
        this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
        this.currentQuestion.IsAttempted !== 1
      ) {
        this.showAttemptedError = true;
        this.errMsg =
          "You have completed all required questions, Please clear answer of any question to submit this answer.";
        return;
      }
      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        serialNumber: this.currentQuestion.SerialNumber,
        partSerialNumber: this.currentQuestion.PartSerialNumber,
        testUserId: this.currentQuestion.TestUserId,
        answerOption: this.currentQuestion.Answer.toString(),
        startedOn: this.currentQuestion.StartedOn,
        review,
        isFromWeb: 1,
      };
      this.$store.dispatch("showLoader", true);
      Mobileapi.submitAnswer(
        data,
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.errors.length) {
            toast.error(`${response.errors[0]}`, {
              timeout: 2000,
            });
          } else {
            // this.serverTime = response.date[0];
            // this.serverTime = moment(this.serverTime).add(1, "seconds");
            // this.startTime = moment(this.serverTime);
            if (this.currentQuestion.QuestionTypeId === 7) {
              this.matchTheFollowingAnswers = this.currentQuestion.Answer.split(",");
              this.optionsForMTF.forEach((e) => {
                e.isSelected = false;
              });
            }
            if (!review) {
              this.currentQuestion.IsMarkedForReview = 0;

              if (
                this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted !==
                  this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
                this.isSubmitTrigger &&
                this.currentQuestion.IsAttempted !== 1 &&
                review === 0
              ) {
                this.currentQuestion.IsAttempted = 1;
                this.attemptedQnsDetail = this.attemptedQnsDetail.map((item, index) => {
                  if (index === this.currentPartIndex) {
                    return { ...item, QnsAttempted: item.QnsAttempted + 1 };
                  }
                  return item;
                });
                this.attemptedQns = this.attemptedQnsDetail.reduce(
                  (accu, curr) => accu + Number(curr.QnsAttempted),
                  0,
                );
              }
              if (
                this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
                  this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
                this.isSubmitTrigger &&
                this.currentQuestion.IsAttempted === 1 &&
                !this.isQnsAttempted
              ) {
                if (this.testDetails.questions.length > 1) {
                  if (Number(this.totalAttemptedQns) - Number(this.attemptedQns) === 0) {
                    this.showAttemptedError = true;
                    this.errMsg =
                      "You have completed all the questions in this test. You may either revise or end the test.";
                  } else if (
                    this.attemptedQnsDetail[this.currentPartIndex].TotalQuestions !==
                    this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts
                  ) {
                    this.showAttemptedError = true;
                    this.errMsg =
                      "You have completed all required questions of this part, You can move to another part.";
                  }
                } else if (
                  this.testDetails.questions.length === 1 &&
                  this.attemptedQnsDetail[this.currentPartIndex].TotalQuestions !==
                    this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts
                ) {
                  this.showAttemptedError = true;
                  this.errMsg =
                    "You have completed all the questions in this test. You may either revise or end the test.";
                } else {
                  this.showAttemptedError = true;
                  this.errMsg =
                    "You have completed all the questions in this test. You may either revise or end the test.";
                }
                this.isSubmitTrigger = false;
              } else if (
                this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
                  this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
                this.isSubmitTrigger &&
                this.currentQuestion.IsAttempted !== 1
              ) {
                this.showAttemptedError = true;
                this.errMsg =
                  "You have completed all required questions, Please clear answer of any question to submit this answer.";
                this.isSubmitTrigger = false;
              }
              this.updateLocalStorage();
              if (!this.gotoQuestionClicked) {
                this.nextFinalQuestion();
              }
              this.gotoQuestionClicked = false;
              if (this.submittedCount === this.testDetails.test.TotalQuestions) {
                // this.showAllQuestionDone = true;
                // this.endTest();
              }
            }

            this.getCurrrentServerTime();
            this.saveToSS();
            if (cb) {
              cb();
            }
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    goToPart(partIndex) {
      this.showPartBtn = false;
      this.isSubmitTrigger = false;
      this.showAttemptedError = false;
      this.currentPartIndex = partIndex;
      const currentPart = this.testDetails.questions[this.currentPartIndex];
      for (let i = 0; i < currentPart.length; i += 1) {
        if (currentPart[i].IsAttempted === 0) {
          this.currentQuestionIndex = i;
          break;
        }
      }
      this.getCurrrentServerTime();
      this.saveToSS();
    },
    endTest() {
      this.isSubmitTrigger = true;
      this.submitAnswer(0);
      clearTimeout(this.timer);
      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        testUserId: this.currentQuestion.TestUserId,
      };
      this.$store.dispatch("showLoader", true);
      Mobileapi.endTest(
        data,
        (response) => {
          localStorage.removeItem("testQuestionList");
          if (response.errors.length) {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.errors[0]}`, {
              timeout: 2000,
            });
          } else if (this.leaveTo) {
            toast.success("Test Submitted Successfully", {
              timeout: 2000,
            });
            this.$router.push(this.leaveTo);
          } else {
            toast.success("Test Submitted Successfully", {
              timeout: 2000,
            });
            window.location.href = `/TestReport/${this.currentQuestion.TestUserId}/${this.type}?feedback=true`;
          }
          this.disableFullScreen();
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    uploadNowAssignmentQuestion() {
      this.InstituteEndTest = false;
      this.InstituteSubjectiveTypeEndTest = true;
      if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.InstTestQuestionSubmitLater = true;
        this.InstituteTestSubTypeQuestion = false;
        this.SolutionUploadImage = [];
        this.calculateUploadedSolution();
        this.filteredSubjectiveQuestionList = this.SubjectiveQuestionList;
      }
    },
    UploadNowSubjectQuestion() {
      this.InstituteEndTest = false;
      this.InstituteSubjectiveTypeEndTest = true;
      if (
        this.currentQuestionIndex ===
          this.testDetails.questions[this.currentPartIndex].length - 1 &&
        this.currentPartIndex < this.testDetails.questions.length - 1
      ) {
        if (this.SubjectiveQuestionList.length === 0) {
          this.currentQuestionIndex = 0;
          this.currentPartIndex += 1;
          this.getCurrrentServerTime();
          this.saveToSS();
          if (this.currentQuestion.QuestionTypeId !== 2) {
            this.InstTestQuestionSubmitLater = true;
            this.InstituteTestSubTypeQuestion = false;
          }
        } else {
          this.InstTestQuestionSubmitLater = true;
          this.InstituteTestSubTypeQuestion = false;
          this.SolutionUploadImage = [];
          this.calculateUploadedSolution();
          this.filteredSubjectiveQuestionList = this.SubjectiveQuestionList.filter(
            (item) => item.PartId === this.currentQuestion.PartId,
          );
        }
      }
      if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        if (this.SubjectiveQuestionList.length === 0) {
          this.endTest();
          toast.success("Test Submitted Successfully", {
            timeout: 2000,
          });
          window.location.href = `/TestReport/${this.currentQuestion.TestUserId}/${this.type}`;
        } else {
          this.InstTestQuestionSubmitLater = true;
          this.InstituteTestSubTypeQuestion = false;
          this.calculateUploadedSolution();
          this.filteredSubjectiveQuestionList = this.SubjectiveQuestionList.filter(
            (item) => item.PartId === this.currentQuestion.PartId,
          );
        }
      }
    },
    calculateUploadedSolution() {
      if (Object.keys(this.testDetails.questions).length > 0) {
        this.SolutionUploadImage = [];
        this.testDetails.questions[this.currentPartIndex].forEach((item1) => {
          if (item1.QuestionTypeId === 2) {
            // this.SubjectiveQuestionList.push(qns);
            if (item1.IsAttempted === 1) {
              this.SolutionUploadImage.push(true);
            } else {
              this.SolutionUploadImage.push(false);
            }
          }
        });
      }
    },
    SubjectiveQuestionImageId(index, QuestionId, partIndex, index2) {
      this.subjectiveCounter += 1;
      this.SelectedQuestionId = QuestionId;
      if (this.type !== 4) {
        this.currentPartIndex = partIndex;
      }
      if (
        this.testDetails.questions[this.currentPartIndex].length <
          this.testDetails.test.TotalQuestions &&
        this.currentQuestion.QuestionTypeId === 2
      ) {
        this.QuestionIndex = index - 1;
        this.qnsStatusIndex = index2;
      } else {
        this.QuestionIndex = index - 1;
        this.qnsStatusIndex = index - 1;
      }

      //
      if (
        this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
        !this.SolutionUploadImage[this.qnsStatusIndex]
      ) {
        this.showAttemptedError = true;
        this.openUploadFolder = false;
        if (this.testDetails.questions.length > 1) {
          if (Number(this.totalAttemptedQns) - Number(this.attemptedQns) === 0) {
            this.errMsg =
              "You have completed all the required questions. You may either revise or submit the answer.";
          } else {
            this.errMsg =
              "You have completed all required questions of this part, You can move to another part.";
          }
        } else {
          this.errMsg =
            "You have completed all the required questions. You may either revise or submit the answer.";
        }
      } else {
        this.openUploadFolder = true;
      }
    },
    base64(event) {
      this.showModalPopUp = false;
      this.SelectedImageName = "";
      this.fileListFinal = [];
      const fileDataTemp = [];
      if (this.type === 4) {
        this.currentPartIndex = 0;
      }
      if (
        this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted !==
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts ||
        this.SolutionUploadImage[this.qnsStatusIndex]
      ) {
        for (let i = 0; i < event.target.files.length; i += 1) {
          const reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          if (event.target.files[i].size > 3000000) {
            this.showModalPopUp = true;
            // eslint-disable-next-line no-const-assign
            this.fileListFinal = [];
            break;
          }
          if (event.target.files.length > 3) {
            toast.error("You are only allowed to upload a maximum of 3 files at a time", {
              timeout: 2000,
            });
            break;
          } else {
            reader.onloadend = () => {
              this.$store.dispatch("showLoader", true);
              this.Base64File = reader.result.split(",")[1];
              // this.Base64File.replace("dataapplication/pdfbase64", "");
              const fileData = {
                fileData: this.Base64File,
                fileName: event.target.files[i].name,
              };
              fileDataTemp.push(fileData);
              if (fileDataTemp.length > 0) {
                this.$store.dispatch("showLoader", false);
              }
              this.isAnswerUploaded = true;
            };
            if (!this.SolutionUploadImage[this.qnsStatusIndex]) {
              // this.currentQuestion.IsAttempted = 1;
              this.attemptedQnsDetail = this.attemptedQnsDetail.map((item, index) => {
                if (index === this.currentPartIndex) {
                  return { ...item, QnsAttempted: item.QnsAttempted + 1 };
                }
                return item;
              });
              this.attemptedQns = this.attemptedQnsDetail.reduce(
                (accu, curr) => accu + Number(curr.QnsAttempted),
                0,
              );
              this.testDetails.questions.forEach((item1, index1) =>
                item1.forEach((item2, index2) => {
                  if (this.QuestionIndex + 1 === item2.questionIndex) {
                    this.testDetails.questions[index1][index2].IsAttempted = 1;
                    this.testDetails.questions[index1][index2].isSkipped = 0;
                    this.updateLocalStorage();
                  }
                }),
              );
            }
            if (
              this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
                this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
              !this.SolutionUploadImage[this.qnsStatusIndex]
            ) {
              if (this.type !== 4) {
                this.showAttemptedError = true;
                if (this.testDetails.questions.length > 1) {
                  if (Number(this.totalAttemptedQns) - Number(this.attemptedQns) === 0) {
                    this.errMsg =
                      "You have completed all the required questions. You may either revise or end the test.";
                  } else {
                    this.errMsg =
                      "You have completed all required questions of this part, You can move to another part.";
                  }
                } else {
                  this.errMsg =
                    "You have completed all the required questions. You may either revise or end the test";
                }
              }
            }
          }
          this.fileListFinal = fileDataTemp;
          this.SelectedImageName = event.target.files[i].name;
        }
        if (this.SelectedImageName.length > 0) {
          this.SolutionUploadImage[this.qnsStatusIndex] = true;
          this.QuestionIndex = null;
        }
        //   }
        const questionData = {
          questionId: this.SelectedQuestionId,
          maximumMarks: 3,
          files: this.fileListFinal,
        };
        this.subjectiveQuesList.push(questionData);
      } else if (this.testDetails.questions.length > 0) {
        this.showAttemptedError = true;
        if (
          this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts
        ) {
          this.errMsg =
            "You have uploaded all the answer of this part. You may either revise or submit the test.";
        }
      }
    },
    InstituteTestSubjectQuestion() {
      this.countSubmitedSubjectiveAnswer = this.SolutionUploadImage.reduce(
        (acc, val) => (val === true ? acc + 1 : acc),
        0,
      );
      if (this.type === 4) {
        const totalQuestionsCount = this.filteredSubjectiveQuestionList.length;
        if (this.countSubmitedSubjectiveAnswer !== totalQuestionsCount) {
          this.subjectiveToggleModal = true;
        }
      } else {
        const allowedAttempts = this.filteredSubjectiveQuestionList.map(
          (item) => item.AllowedAttempts,
        );
        const uniqueAttempts = [...new Set(allowedAttempts)];
        const totalSubjectiveAttempted = uniqueAttempts.reduce((total, num) => total + num);
        if (totalSubjectiveAttempted !== this.countSubmitedSubjectiveAnswer) {
          this.subjectiveToggleModal = true;
        }
      }
      if (!this.subjectiveToggleModal || this.allowSubmitSubjective) {
        this.$store.dispatch("showLoader", true);
        const user = this.$store.getters.user;
        const data = {
          instituteTestId: this.testUserId,
          affiliationId: Number(user.AffiliationId),
          batchId: Number(user.CenterCodeId),
          courseId: String(user.CourseId),
          subjectId: Number(this.SubjectId),
          studentId: Number(user.UserId),
          questionList: this.subjectiveQuesList,
          testType: this.type,
        };
        Mobileapi.InstituteTestSubjectiveQuestionListSubmit(data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.SubmittedInstituteSubjective = response;
          this.SubjectiveQuestionTypeId =
            this.testDetails.questions[this.currentPartIndex][
              this.currentQuestionIndex
            ].QuestionTypeId;
          this.SubjectiveQuestionIndexLength =
            this.testDetails.questions[this.currentPartIndex][this.currentQuestionIndex].length;
          if (response.responseCode === 200) {
            if (
              this.currentQuestionIndex ===
                this.testDetails.questions[this.currentPartIndex].length - 1 &&
              this.currentPartIndex < this.testDetails.questions.length - 1
            ) {
              // this.currentQuestionIndex = 0;
              // this.currentPartIndex += 1;
              this.getCurrrentServerTime();
              this.saveToSS();
              if (this.currentQuestion.QuestionTypeId !== 2) {
                this.InstTestQuestionSubmitLater = true;
                this.InstituteTestSubTypeQuestion = false;
              }
            }
            if (!this.allowSubmitSubjective) {
              if (this.type !== 4) {
                this.showAttemptedError = true;
                if (Number(this.totalAttemptedQns) - Number(this.attemptedQns) === 0) {
                  this.errMsg =
                    "You have completed all the questions in this test. You may either revise or end the test.";
                } else {
                  this.errMsg =
                    "You have completed all required questions of this part, You can move to another part.";
                }
              }
            }
            this.allowSubmitSubjective = false;
            this.subjectiveToggleModal = false;

            this.SwitchToNextPart = false;
          }
        });
      }
    },
    endSubjectiveTest() {
      if (
        this.currentsubjectiveQuestionId ===
          this.testDetails.questions[this.currentPartIndex].length - 1 &&
        this.currentPartIndex < this.testDetails.questions.length - 1
      ) {
        this.currentQuestionIndex = 0;
        this.currentPartIndex += 1;
        this.getCurrrentServerTime();
        this.saveToSS();
        this.subjectiveToggleModal = false;
        if (this.currentQuestion.QuestionTypeId !== 2) {
          this.InstTestQuestionSubmitLater = true;
          this.InstituteTestSubTypeQuestion = false;
          this.InstituteEndTest = true;
          this.InstituteSubjectiveTypeEndTest = false;
        }
      } else if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.endTest();
        this.InstituteEndTest = true;
        this.InstituteSubjectiveTypeEndTest = false;
      }
      for (let i = 0; i < this.SubjectiveQuestionList.length; i += 1) {
        if (this.SolutionUploadImage[i] === true) {
          this.$store.dispatch("showLoader", true);
          const user = this.$store.getters.user;
          const data = {
            instituteTestId: this.testUserId,
            affiliationId: Number(user.AffiliationId),
            batchId: Number(user.CenterCodeId),
            courseId: Number(user.CourseId),
            subjectId: Number(this.SubjectId),
            studentId: Number(user.UserId),
            questionList: this.subjectiveQuesList,
            testType: this.type,
          };
          Mobileapi.InstituteTestSubjectiveQuestionListSubmit(data, (response) => {
            this.$store.dispatch("showLoader", false);
            this.SubmittedInstituteSubjective = response;
            if (response.responseCode === 200) {
              if (
                this.currentQuestionIndex ===
                  this.testDetails.questions[this.currentPartIndex].length - 1 &&
                this.currentPartIndex < this.testDetails.questions.length - 1
              ) {
                this.currentQuestionIndex = 0;
                this.currentPartIndex += 1;
                this.getCurrrentServerTime();
                this.saveToSS();
                if (this.currentQuestion.QuestionTypeId !== 2) {
                  this.InstTestQuestionSubmitLater = true;
                  this.InstituteTestSubTypeQuestion = false;
                }
              }
            }
          });
          break;
        }
      }
    },
  },
  computed: {
    storage_Url() {
      return process.env.VUE_APP_SL_URL;
    },
    isQuestionOptionVisible() {
      return (
        ((this.currentQuestion.Q_LATEX_Status !== "Y" || !this.currentQuestion.Q_LATEX) &&
          this.currentQuestion.QuestionTypeId !== 4 &&
          this.currentQuestion.QuestionTypeId !== 7 &&
          this.currentQuestion.QuestionTypeId !== 8) ||
        (!this.currentQuestion.AnswerOption_A &&
          !this.currentQuestion.AnswerOption_B &&
          !this.currentQuestion.AnswerOption_C &&
          !this.currentQuestion.AnswerOption_D &&
          this.currentQuestion.QuestionTypeId !== 4 &&
          this.currentQuestion.QuestionTypeId !== 7 &&
          this.currentQuestion.QuestionTypeId !== 8)
      );
    },
    currentQuestion() {
      return this.testDetails.questions[this.currentPartIndex][this.currentQuestionIndex];
    },
    savedForLaterCount() {
      let count = 0;
      this.testDetails.questions.forEach((parts) => {
        count += parts.filter((q) => q.IsMarkedForReview && !q.IsAttempted).length;
      });
      return count;
    },
    currentAllQuestionIndex() {
      const count =
        this.testDetails.questions[this.currentPartIndex][this.currentQuestionIndex].questionIndex;
      return count;
    },
    submittedCount() {
      let count = 0;
      this.testDetails.questions.forEach((parts) => {
        count += parts.filter((q) => q.IsAttempted).length;
      });
      return count;
    },
    notAttempted() {
      let count = 0;
      if (this.type !== 4) {
        this.testDetails.questions.forEach((parts) => {
          count += parts.filter(
            (q) => q.isSkipped && q.Answer === "" && q.IsMarkedForReview === 0,
          ).length;
        });
      } else {
        this.testDetails.questions.forEach((parts) => {
          count += parts.filter((q) => q.isSkipped === 1).length;
        });
      }
      return count;
    },
    notVisited() {
      let count = 0;
      this.testDetails.questions.forEach((parts) => {
        count += parts.filter((q) => q.isSkipped || q.IsMarkedForReview || q.IsAttempted).length;
      });
      return count;
    },
    ...mapGetters(["user"]),
  },
  components: {
    Modal,
    Modal2,
    ViewQuestionModalVue,
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
}

.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* .mtf-clear-single {
  background-color: #EA7B7B;
  color: white;
  border-radius: 50%;
  font-size: 18px !important;
  padding: 1px;
  cursor: pointer;
} */
.mtf-clear-single-img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.023);
  background-color: #f9f9f9;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  display: block;
  background-color: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e5e5e5;
}

.conduct-test {
  padding: 20px;
  height: 100vh;
}

.small-screen-only {
  display: none;
}

button {
  cursor: pointer;
}
button:hover {
  /* box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%), 0 3px 1px -1px rgb(0 0 0 / 20%); */
}
button[disabled] {
  background-color: gray !important;
  color: #111 !important;
  pointer-events: none !important;
  opacity: 0.5;
}

button:focus {
  background-color: none;
}

.dark-color {
  color: #393939 !important;
}

.white-color {
  color: #fff;
}

.green-bg {
  background-color: #45a664 !important;
}

.orange-bg {
  background-color: #fb8c00 !important;
}
.red-bg {
  background-color: #ff4949 !important;
}

.card {
  border-radius: 5px;
  background-color: #fff;
  height: 100%;
  padding: 15px;
}

.question-header {
  border-bottom: 1px solid #eee;
  padding: 5px 10px 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: relative;
}

.container {
  max-width: 100% !important;
  width: 100%;
}

.question-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.endTest-btn {
  background-color: #ff4949;
  padding: 8px 24px;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  border: none;
}
.pause-btn {
  background-color: #0984e3;
  padding: 8px 24px;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  border: none;
  margin-right: 10px;
}
.revision-modal {
  position: absolute;
  width: 304px;
  height: 70px;
  right: -15px;
  top: 2px;
  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid #e0e4f0;
}
.modal-close {
  width: 34px;
  height: 34px;
  background: #ff7675;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.revision-button {
  width: 194px;
  height: 28px;
  background: #f3f5fc;
  border-radius: 4px;
  color: #3751ff;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* display: flex; */
}

.revision-button-1 {
  width: 194px;
  height: 28px;
  background: #3751ff;
  border-radius: 4px;
  color: #f3f5fc;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* display: flex; */
}
.diagramURL {
  overflow: hidden !important;
  height: auto;
  max-width: 100%;
  flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
}

.content-even {
  justify-content: space-evenly !important;
}

.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.content-even {
  justify-content: space-evenly !important;
}
.content-cntr {
  justify-content: center !important;
}
.revision-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.revision-img-1 {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.blur-class {
  filter: blur(2px) !important;
}
.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}
.end-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}
.button-size {
  width: 40px !important;
  height: 34px !important;
  margin-right: 10px;
  cursor: pointer;
}
.feature-btn {
  height: 38px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  justify-content: center;
  /* width: 160px !important; */
  font-size: 16px;
  gap: 5px;
  font-weight: 600;
}

.saveLater-btn {
  background: #eeeded;
  border: 1px solid #6b60cd !important;
  color: #6b60cd !important;
  padding: 5px 8px;
}
.saveLater-btn.disable {
  background-color: #cacaca !important;
  color: #817e7e !important;
  pointer-events: none;
  opacity: 0.5;
  /* background-color: #F1F1F1;
    pointer-events: none;
    color: #8E8E8E !important; */
  border: none;
}

.clearAnswer-btn {
  background: #ea7b7b;
  color: #fff;
}

.submitNext-btn {
  color: #fff;
  background-color: #9642be;
  padding: 12px;
  font-size: 16px;
  border: none;
  display: flex;
  font-weight: 600;
  align-items: center;
  border-radius: 5px;
  gap: 5px;
}
.submitNext-btn.disable {
  background-color: #9642be6b;
  pointer-events: none;
}
.submitNext-btn:focus {
  background-color: #9642be;
}

.option-btn {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  color: #000;
  height: 45px;
  width: 60px;
  /* border-radius: 50%; */
  font-size: 22px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* font-weight: 600; */
}

.stats-option-btn {
  /* border: 1px solid #e5e5e5; */
  height: 40px;
  width: 40px;
  font-size: 16px;
  /* margin-right: 16px;
  margin-bottom: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  background-color: rgb(162, 164, 169);
  border: 1px solid #dcdcdc;
  color: #ffffff !important;
}
/* .stats-option-btn.current {
  background-color: #6b60cd;
  color: #fff;
} */

.navigation-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #9642be;
  border: 1px solid #9642be;
  background-color: #9742be13;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.navigation-btn:hover {
  color: #fff;
  background-color: #9642be;
}

.test-stats {
  /* padding-top: 20px; */
}

.status-card {
  border-radius: 20px;
  background-color: #eaecf1;
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 14px;
}

.status-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
}

.status-icon {
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* old conduct test css classes  */

.answer_option {
  position: relative;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-right: none;
  /* border-radius: 5px; */
  background-color: #fff;
  padding: 12px 5px;
  margin-top: 10px;
  display: block;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-weight: 500 !important;
}

.option-btn i.uncheck,
.answer_option i.uncheck {
  display: unset;
}

.option-btn i.check,
.answer_option i.check {
  display: none;
}

.option-btn.green-bg i.uncheck,
.answer_option.green-bg i.uncheck {
  display: none;
}

.option-btn.green-bg i.check,
.answer_option.green-bg i.check {
  display: unset;
}

.border-none {
  border: none;
}

.match-the-following-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.option-button {
  width: 17px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option-img {
  width: 4px;
  height: 19px;
  display: flex;
  justify-content: center;
}

.match-the-following-item p {
  border-radius: 5px;
  border: 1px solid #9642be;
  padding: 10px 20px;
  margin: 0;
  background-color: #9642be;
  color: #fff;
  border: 1px solid #fff;
}

.match-the-following-item button {
  border-radius: 5px;
  border: 1px solid #111;
  background-color: #fff;
  /* padding: 10px 20px; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 5px !important;
}

.Upload-Later {
  display: flex;
  justify-content: center;
}

.Upload-Later .ChangeProfile .UploadLaterIcon {
  width: 32px;
  height: 32px;
  margin-left: 25%;
}

@media only screen and (max-width: 990px) {
  .box-shadow-none {
    box-shadow: none !important;
  }
  .side-panel {
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 19%);
    position: fixed;
    left: 100% !important;
    top: 0;
    width: 350px !important;
    transition: all 0.5s;
    z-index: 99;
  }

  .active {
    left: calc(100% - 350px) !important;
  }

  .side-panel-button {
    background: #3d3d3d;
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 18px 30px;
    color: #fff;
    font-weight: 700;
    border-radius: 80px 20px 0 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 195px;
    text-align: center;
    left: -122px;
    top: 25%;
    transform: rotate(-90deg);
    cursor: pointer;
  }
  .small-screen-only {
    display: inherit;
  }
  .conduct-test {
    height: 100% !important;
    padding: 10px;
  }
  .pX-10px {
    padding: 10px 0;
  }
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}
.skyblue-text {
  color: #d70000 !important;
}

.earn-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}
.hw-to-earn-content {
  padding: 35px !important;
}

.attempted-err {
  width: 450px;
  max-height: 500px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.cancel-button {
  width: 125px;
  padding-right: 5px;
  padding-left: 5px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff7675;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  table {
    table-layout: fixed;
    width: 700px;
  }
}
.enable-savelater {
  color: #6b60cd !important;
}

.blueBg-whiteColor {
  background-color: #6b60cd !important;
  color: #fff !important;
}

.negative-marks {
  border: 1px solid #ff4949;
  border-radius: 50%;
  background-color: #ff4949;
  color: #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marks-per-questions-box {
  position: absolute;
  bottom: -50px;
  right: 20px;
  display: flex;
  gap: 5px;
}
.positive-marks {
  border: 1px solid #45a664;
  border-radius: 50%;
  background-color: #45a664;
  color: #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-num {
  position: relative;
  background-color: #a2a4a9;
  color: #fff;
  border-radius: 0 50% 50% 0;
  bottom: -35px;
  left: -25px;
  height: -25px;
  width: 45px;
  padding: 10px;
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
}
.reset-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}
.no-button {
  padding: 5px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.no-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: inherit;
  opacity: 1;
  pointer-events: all;
  width: 15px;
  height: 15px;
}
</style>
