<template>
  <div v-if="testDetails" class="extraBottomSpace">
    <div class="card">
      <div class="card-content">
        <span class="card-title slcolor-text"
          ><b>Test - {{ testDetails.test.Description }}</b></span
        >
        <div class="row nmb">
          <div class="col s4">
            <h6>
              <i
                class="material-icons red-text text-darken-3"
                style="position: relative; top: 6px; margin-right: 10px"
                >watch_later</i
              >
              <span :class="{ timerRed: timeLeft < 300 }"
                ><span v-if="time.hours < 10">0</span>{{ time.hours }}:<span
                  v-if="time.minutes < 10"
                  >0</span
                >{{ time.minutes }}:<span v-if="time.seconds < 10">0</span>{{ time.seconds }}</span
              >
            </h6>
          </div>
          <div class="col s4 center-align">
            <b
              >Question: {{ currentQuestionIndex + 1 }} of
              {{ testDetails.questions[currentPartIndex].length }}
              <span v-if="testDetails.questions.length > 1">({{ currentQuestion.Part }})</span></b
            ><br />
            <span v-if="testDetails && testDetails.questions[currentPartIndex][0].NegativeMarks"
              >Negative Marks: {{ testDetails.questions[currentPartIndex][0].NegativeMarks }} per
              question<br
            /></span>
            <span
              v-if="
                testDetails &&
                testDetails.questions[currentPartIndex][0].AllowedAttempts &&
                testDetails.questions[currentPartIndex][0].AllowedAttempts !==
                  testDetails.questions[currentPartIndex].length
              "
              >Allowed Attempts: {{ testDetails.questions[currentPartIndex][0].AllowedAttempts }}
              <span v-if="testDetails.questions.length > 1">({{ currentQuestion.Part }})</span><br
            /></span>
            Not Attempted: {{ Number(totalAttemptedQns) - Number(attemptedQns) }} of
            {{ totalAttemptedQns }}
            <!-- Not Attempted: {{ testDetails.test.TotalQuestions - submittedCount - savedForLaterCount }} of {{
                testDetails.test.TotalQuestions
            }} -->
          </div>
          <div class="col s4 right-align">
            <button v-if="InstituteEndTest" class="btn red darken-3" @click="toggleModal = true">
              <i class="material-icons right">pan_tool</i>END TEST
            </button>
            <button
              v-if="InstituteSubjectiveTypeEndTest"
              class="btn red darken-3"
              @click="subjectiveToggleModal = true"
            >
              <i class="material-icons right">pan_tool</i>END TEST
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentQuestion.QuestionTypeId !== 2" class="card">
      <div class="card-image question">
        <div style="margin-bottom: 10px" v-if="currentQuestion.QuestionComprehension != null">
          <a> {{ currentQuestion.QuestionComprehension }}</a>
        </div>
        <div v-if="currentQuestion.Q_LATEX_Status === 'Y' && currentQuestion.Q_LATEX">
          <vue-mathjax
            :formula="currentQuestion.Q_LATEX"
            :options="VueMathJaxOptions"
            :safe="false"
            class="space-break fs-18 fw-500"
          />
          <div
            v-if="
              currentQuestion.AnswerOption_A &&
              currentQuestion.AnswerOption_B &&
              currentQuestion.AnswerOption_C &&
              currentQuestion.AnswerOption_D
            "
          >
            <button
              class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
              :class="{
                'blue darken-4 white-text border-none':
                  (currentQuestion.IsMarkedForReview &&
                    currentQuestion.ReviewAnswerOption.includes('A')) ||
                  currentQuestion.Answer.includes('A'),
              }"
              @click="selectAnswerOption('A')"
            >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                >check_box_outline_blank</i
              >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                >check_box</i
              ><span v-if="!currentQuestion.AnswerOption_A.includes('(A)')">(A)</span>
              <vue-mathjax
                :formula="currentQuestion.AnswerOption_A"
                :options="VueMathJaxOptions"
                :safe="false"
              />
            </button>
            <button
              class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
              :class="{
                'blue darken-4 white-text border-none':
                  (currentQuestion.IsMarkedForReview &&
                    currentQuestion.ReviewAnswerOption.includes('B')) ||
                  currentQuestion.Answer.includes('B'),
              }"
              @click="selectAnswerOption('B')"
            >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                >check_box_outline_blank</i
              >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                >check_box</i
              ><span v-if="!currentQuestion.AnswerOption_B.includes('(B)')">(B)</span>
              <vue-mathjax
                :formula="currentQuestion.AnswerOption_B"
                :options="VueMathJaxOptions"
                :safe="false"
              />
            </button>
            <button
              class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
              :class="{
                'blue darken-4 white-text border-none':
                  (currentQuestion.IsMarkedForReview &&
                    currentQuestion.ReviewAnswerOption.includes('C')) ||
                  currentQuestion.Answer.includes('C'),
              }"
              @click="selectAnswerOption('C')"
            >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                >check_box_outline_blank</i
              >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                >check_box</i
              ><span v-if="!currentQuestion.AnswerOption_C.includes('(C)')">(C)</span>
              <vue-mathjax
                :formula="currentQuestion.AnswerOption_C"
                :options="VueMathJaxOptions"
                :safe="false"
              />
            </button>
            <button
              class="fs-18 fw-600 dark-text m-0 mt-5 space-break answer_option"
              :class="{
                'blue darken-4 white-text border-none':
                  (currentQuestion.IsMarkedForReview &&
                    currentQuestion.ReviewAnswerOption.includes('D')) ||
                  currentQuestion.Answer.includes('D'),
              }"
              @click="selectAnswerOption('D')"
            >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                >check_box_outline_blank</i
              >
              <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                >check_box</i
              ><span v-if="!currentQuestion.AnswerOption_D.includes('(D)')">(D)</span>
              <vue-mathjax
                :formula="currentQuestion.AnswerOption_D"
                :options="VueMathJaxOptions"
                :safe="false"
              />
            </button>
          </div>
        </div>

        <img :src="`${storage_Url + '/' + currentQuestion.QuestionDiagramURL}`" v-else />
      </div>
      <div class="card-action right-align">
        <button
          class="btn left"
          @click="saveForLater()"
          :disabled="currentQuestion.IsMarkedForReview === 1 || currentQuestion.IsAttempted === 1"
        >
          <i class="material-icons right">bookmark_border</i>Save for Later
        </button>
        <button
          class="btn blue darken-4"
          @click="prevQuestion()"
          :disabled="currentPartIndex === 0 && currentQuestionIndex === 0"
        >
          <i class="material-icons left">arrow_back_ios</i>PREVIOUS
        </button>
        <button
          class="btn blue darken-4"
          @click="
            isSubmitTrigger = false;
            nextQuestion();
          "
          :disabled="
            currentPartIndex === testDetails.questions.length - 1 &&
            currentQuestionIndex === testDetails.questions[currentPartIndex].length - 1
          "
        >
          <i class="material-icons right">arrow_forward_ios</i>SKIP
        </button>
      </div>
    </div>

    <div v-if="currentQuestion.QuestionTypeId === 2" class="card">
      <div v-if="InstituteTestSubTypeQuestion">
        <div class="card-image question">
          <div v-if="currentQuestion.Q_LATEX_Status === 'Y' && currentQuestion.Q_LATEX">
            <vue-mathjax
              :formula="currentQuestion.Q_LATEX"
              :options="VueMathJaxOptions"
              :safe="false"
              class="space-break fs-18 fw-500"
            />
            <div
              v-if="
                currentQuestion.AnswerOption_A &&
                currentQuestion.AnswerOption_B &&
                currentQuestion.AnswerOption_C &&
                currentQuestion.AnswerOption_D
              "
            >
              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                <span v-if="!currentQuestion.AnswerOption_A.includes('(A)')">(A)</span>
                <vue-mathjax
                  :formula="currentQuestion.AnswerOption_A"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
              </h1>
              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                <span v-if="!currentQuestion.AnswerOption_B.includes('(B)')">(B)</span>
                <vue-mathjax
                  :formula="currentQuestion.AnswerOption_B"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
              </h1>
              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                <span v-if="!currentQuestion.AnswerOption_C.includes('(C)')">(C)</span>
                <vue-mathjax
                  :formula="currentQuestion.AnswerOption_C"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
              </h1>
              <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                <span v-if="!currentQuestion.AnswerOption_D.includes('(D)')">(D)</span>
                <vue-mathjax
                  :formula="currentQuestion.AnswerOption_D"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
              </h1>
            </div>
          </div>

          <img :src="`${storage_Url + '/' + currentQuestion.QuestionDiagramURL}`" v-else />
        </div>
        <div class="card-action">
          <div>
            <div class="col s12 Upload-Later">
              <div class="ChangeProfile" style="line-height: 1.5px" @click="SubjectiveAttempted()">
                <label style="font-size: 25px; color: #6d2d7e">
                  <img class="UploadLaterIcon" :src="require(`../assets/cloud-plus.png`)" />
                  <h6>Upload Later</h6>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="InstTestQuestionSubmitLater" class="card">
        <div class="card subjective-main-card">
          <div class="internal-content">
            <table>
              <thead class="table-head">
                <tr class="center-align">
                  <th>Question No</th>
                  <th>Upload Images</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody v-for="(data, index) in SubjectiveQuestionList" :key="index">
                <tr>
                  <td>
                    {{ index + 1 }}.<a
                      style="text-decoration: underline; margin-left: 5px"
                      @click="ViewSubQuestion(data.questionDiagramURL)"
                    >
                      View Question Image</a
                    >
                  </td>
                  <td v-if="!SolutionUploadImage[index]">Please Upload Image</td>
                  <td v-if="SolutionUploadImage[index]" style="color: green">Uploaded File</td>
                  <!-- <td>{{this.SelectedImageUpload}}</td> -->
                  <td>
                    <label for="file1" style="font-size: 25px; color: #6d2d7e">
                      <input
                        id="file1"
                        type="file"
                        multiple
                        style="display: none"
                        accept="image/*"
                        @change="base64($event)" />
                      <img
                        class="Uploadbox"
                        :src="require(`../assets/Uploadbox.png`)"
                        style="width: 32px; height: 32px; margin-right: 90px"
                        @click="SubjectiveQuestionImageId(index, data.questionId)"
                    /></label>
                    <!-- <img class="Vector" src="../assets/Vector.png" @click="ViewSubjcetiveUploadedImage(index)" /> -->
                  </td>
                  <td v-if="!SolutionUploadImage[index]" style="color: red">
                    <i class="material-icons">clear</i>
                  </td>
                  <td v-if="SolutionUploadImage[index]" style="color: green">
                    <i class="material-icons" style="color: green">check</i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-action">
        <div class="row nmb">
          <div v-if="currentQuestion.QuestionTypeId !== 2" class="col s6">
            <input
              v-if="currentQuestion.QuestionTypeId === 4"
              v-model="currentQuestion.Answer"
              placeholder="enter your answer"
              type="text"
              class="browser-default"
              style="width: 200px"
            />
            <div style="display: inline-block" v-if="isQuestionOptionVisible">
              <button
                class="btn btn-answeroption"
                :class="{
                  'blue darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('A')) ||
                    currentQuestion.Answer.includes('A'),
                }"
                @click="selectAnswerOption('A')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                  >check_box</i
                >
                A
              </button>
              <button
                class="btn btn-answeroption"
                :class="{
                  'blue darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('B')) ||
                    currentQuestion.Answer.includes('B'),
                }"
                @click="selectAnswerOption('B')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                  >check_box</i
                >
                B
              </button>
              <button
                class="btn btn-answeroption"
                :class="{
                  'blue darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('C')) ||
                    currentQuestion.Answer.includes('C'),
                }"
                @click="selectAnswerOption('C')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                  >check_box</i
                >
                C
              </button>
              <button
                class="btn btn-answeroption"
                :class="{
                  'blue darken-4 white-text noborder':
                    (currentQuestion.IsMarkedForReview &&
                      currentQuestion.ReviewAnswerOption.includes('D')) ||
                    currentQuestion.Answer.includes('D'),
                }"
                @click="selectAnswerOption('D')"
              >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons uncheck"
                  >check_box_outline_blank</i
                >
                <i v-if="currentQuestion.QuestionTypeId === 3" class="material-icons check"
                  >check_box</i
                >
                D
              </button>
            </div>
            <div class="match-the-following" v-if="currentQuestion.QuestionTypeId === 7">
              <div class="match-the-following-item" v-for="index in 4" :key="index">
                <p>{{ optionsForMTF[index - 1] }}</p>
                <div>
                  <button
                    @click="selectAnswerOption(optionsForMTF[index - 1] + '-P')"
                    :class="{
                      'blue darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(
                            optionsForMTF[index - 1] + '-P',
                          )
                        : currentQuestion.Answer.includes(optionsForMTF[index - 1] + '-P'),
                    }"
                  >
                    P
                  </button>
                  <button
                    @click="selectAnswerOption(`${optionsForMTF[index - 1]}-Q`)"
                    :class="{
                      'blue darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(
                            `${optionsForMTF[index - 1]}-Q`,
                          )
                        : currentQuestion.Answer.includes(`${optionsForMTF[index - 1]}-Q`),
                    }"
                  >
                    Q
                  </button>
                  <button
                    @click="selectAnswerOption(`${optionsForMTF[index - 1]}-R`)"
                    :class="{
                      'blue darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(
                            `${optionsForMTF[index - 1]}-R`,
                          )
                        : currentQuestion.Answer.includes(`${optionsForMTF[index - 1]}-R`),
                    }"
                  >
                    R
                  </button>
                  <button
                    @click="selectAnswerOption(`${optionsForMTF[index - 1]}-S`)"
                    :class="{
                      'blue darken-4 white-text border-none': currentQuestion.IsMarkedForReview
                        ? currentQuestion.ReviewAnswerOption.includes(
                            `${optionsForMTF[index - 1]}-S`,
                          )
                        : currentQuestion.Answer.includes(`${optionsForMTF[index - 1]}-S`),
                    }"
                  >
                    S
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentQuestion.QuestionTypeId === 2">
            <!-- <div style="display: flex;justify-content: center;margin-top: 50px;">
                        <div v-for="(image, index) in images" :key="index">
                            <img class="selectedImage" :src="image" />
                        </div>
                    </div> -->
            <div v-if="InstituteTestSubTypeQuestion">
              <div class="left" style="padding-bottom: 35px; color: red">
                <h6>Note:- You can upload a multiple answer files in the end</h6>
              </div>
              <div>
                <button
                  class="btn-large right"
                  @click="UploadNowSubjectQuestion()"
                  :disabled="
                    this.currentQuestionIndex !=
                    this.testDetails.questions[this.currentPartIndex].length
                  "
                >
                  <i class="material-icons right">next_plan</i>Upload Now
                </button>
              </div>
            </div>
            <div v-if="InstTestQuestionSubmitLater">
              <div class="left" style="padding-bottom: 35px; color: green">
                <h6>Note:- Now You Can Submit</h6>
              </div>
              <div>
                <button class="btn-large right" @click="InstituteTestSubjectQuestion()">
                  <i class="material-icons right">next_plan</i>Submit Now
                </button>
              </div>
            </div>
          </div>
          <div v-if="currentQuestion.QuestionTypeId !== 2" class="col s6">
            <button
              class="btn-large right"
              @click="
                isSubmitTrigger = true;
                submitFinalAnswer(0);
              "
            >
              <i class="material-icons right">next_plan</i>Submit and Next
            </button>
          </div>
        </div>
        <div class="row nmb" v-if="currentQuestion.QuestionTypeId !== 2">
          <div class="col s6">
            <button
              v-if="currentQuestion.IsAttempted === 1"
              class="btn btn-small red darken-3"
              @click="clearAnswerModal = true"
            >
              Clear Answer
            </button>
          </div>
          <div class="col s6">
            <span
              v-if="currentQuestion.IsAttempted === 1"
              class="orange-text text-darken-3 right"
              style="margin: 10px 5px 0px 0px"
              >Question already attempted</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="side-panel" :class="{ active: toggleSidePanel }">
      <div class="side-panel-button" @click="toggleSidePanel = !toggleSidePanel">QUESTIONS</div>
      <a
        class="btn-floating btn-small"
        style="position: absolute; right: 20px; top: 20px"
        @click="toggleSidePanel = false"
        ><i class="material-icons">close</i></a
      >
      <div class="side-panel-content" :style="{ height: `${clientHeight - 60}px` }">
        <h6>STATUS</h6>
        <div style="padding: 20px 0px">
          <div class="stat-btn">
            <span class="blue darken-4">{{ submittedCount }}</span
            >Submitted
          </div>
          <div class="stat-btn">
            <span class="orange darken-1">{{ savedForLaterCount }}</span
            >Saved for Later
          </div>
          <div class="stat-btn">
            <span class="notattempted">{{
              testDetails.test.TotalQuestions - submittedCount - savedForLaterCount
            }}</span
            >Not Attempted
          </div>
        </div>
        <h6>QUESTION NAVIGATOR</h6>
        <br v-if="testDetails.questions.length === 1" />
        <div v-for="(question, index) in testDetails.questions" :key="`q${index}`">
          <p v-if="testDetails.questions.length > 1">Part {{ index + 1 }}</p>
          <span
            v-for="(q, index2) in question"
            :key="`q2${index2}`"
            class="btn btn-navigation"
            :class="{
              current:
                q.PartSerialNumber === currentPartIndex + 1 &&
                q.SerialNumber === currentQuestionIndex + 1,
              'orange darken-1 white-text noborder': q.IsMarkedForReview && !q.IsAttempted,
              'blue darken-4 white-text noborder': q.IsAttempted,
            }"
            @click="gotoQuestion(index, index2)"
          >
            {{ q.questionIndex }}
          </span>
        </div>
      </div>
    </div>

    <div class="portrait">
      <div class="valign-wrapper">
        <div class="valign">
          <div style="margin-bottom: 100px">
            <div class="rotate"></div>
            <h5 class="light">Please rotate your device</h5>
            <span
              >We don't support portrat mode yet. Rotate your device to landscape mode for the best
              experience</span
            >
          </div>
        </div>
      </div>
    </div>

    <Modal :show="toggleModal" @close="toggleModal = false" :showFooter="true">
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>End Test</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">Are you sure you want to end this test?</div>
        </div>
      </template>
      <template v-slot:footer>
        <a @click="toggleModal = false" class="btn-flat">Cancel</a>
        <button
          @click="endTest()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          END TEST
        </button>
      </template>
    </Modal>
    <Modal
      :show="leavePageModal"
      @close="
        leavePageModal = false;
        leaveTo = null;
      "
      :showFooter="true"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>Leave Page</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            Are you sure you want to leave this page? This will end this test?
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a
          @click="
            leavePageModal = false;
            leaveTo = null;
          "
          class="btn-flat"
          >Cancel</a
        >
        <button
          @click="endTest()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          OK
        </button>
      </template>
    </Modal>
    <Modal :show="clearAnswerModal" @close="clearAnswerModal = false" :showFooter="true">
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>Clear Answer</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            Are you sure you want to clear the answer submitted for this question?
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a @click="clearAnswerModal = false" class="btn-flat">Cancel</a>
        <button
          @click="clearAnswer()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          OK
        </button>
      </template>
    </Modal>
    <Modal
      :show="ViewSubTypeQuestion"
      @close="ViewSubTypeQuestion = false"
      :showCloseBtn="false"
      width="700px"
    >
      <template v-slot:body>
        <table>
          <tr>
            <td class="center col s12">
              <img
                class="responsive-img"
                draggable="false"
                :src="`${this.SubjectiveQuestionImage}`"
              />
            </td>
          </tr>
        </table>
      </template>
    </Modal>
    <Modal
      :show="subjectiveToggleModal"
      @close="subjectiveToggleModal = false"
      :showFooter="true"
      width="500px"
    >
      <template v-slot:body>
        <div v-if="endtest">
          <div>
            <div class="row">
              <div class="col s12 center">
                <h5>End Test</h5>
              </div>
            </div>
            <div>
              <div class="col s12 center">
                You have not submitted images for some of the questions in the test. Do you still
                want to submit ?
              </div>
              <div style="display: flex; justify-content: space-around; margin-top: 25px">
                <a @click="subjectiveToggleModal = false" class="btn-flat">Cancel</a>
                <button
                  @click="endSubjectiveTest()"
                  class="waves-effect waves-red btn-flat materialize-red-text"
                  style="width: 100px"
                >
                  End Test
                </button>
              </div>
              <!-- <div v-else class="flex flex-between">
                <button @click="subjectiveToggleModal = false" class="waves-effect waves-red btn-flat materialize-red-text"
                  style=" background: aliceblue; width: 90px;margin-top: 20px; position: relative; left: 50%;top: 25px;right: 50%;transform: translate(-50%, -50%);" v-else>Ok</button>
              </div> -->
            </div>
          </div>
          <Modal>
            <template v-slot:footer class="center">
              <a @click="subjectiveToggleModal = false" class="btn-flat">Cancel</a>
              <button
                @click="endSubjectiveTest()"
                class="waves-effect waves-red btn-flat materialize-red-text"
                style="width: 100px"
              >
                END TEST
              </button>
            </template>
          </Modal>
        </div>
        <div v-if="SwitchToNextPart">
          <div>
            <div class="row">
              <div class="col s12 center">
                <h5>Submit Subjective part</h5>
              </div>
            </div>
            <div class="row">
              <div class="col s12 center"></div>
            </div>
            <div>
              <div class="col s12 center">
                You have not submitted images for some of the questions in the test . Do you still
                want to move to next part ?
              </div>
              <div style="display: flex; justify-content: space-around; margin-top: 25px">
                <a @click="subjectiveToggleModal = false" class="btn-flat">Cancel</a>
                <button
                  @click="endSubjectiveTest()"
                  class="waves-effect waves-red btn-flat materialize-red-text"
                  style="width: 100px"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal2 :show="showModalPopUp" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <span class="fw-700 skyblue-text center" style="font-size: 20px">File size Alert</span>
          <p
            @click="showModalPopUp = false"
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <div class="hw-to-earn-content">
          <span class="dtitle" style="font-weight: 700; font-size: 20px"
            >The File size should be less than 1MB</span
          >
          <!-- <span class="dtitle"></span> -->
        </div>
      </template>
    </Modal2>
    <Modal :show="showAllQuestionDone" @close="showAllQuestionDone = false" :showFooter="true">
      <template v-slot:body>
        <div class="row">
          <div class="col s12">
            <h5>End Test</h5>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            All the Questions have been attempted,If you want to submit test<br />
            Please click on end test.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a @click="showAllQuestionDone = false" class="btn-flat">Cancel</a>
        <button
          @click="endTest()"
          class="waves-effect waves-red btn-flat materialize-red-text"
          style="width: 100px"
        >
          END TEST
        </button>
      </template>
    </Modal>
    <Modal2
      :show="showAttemptedError"
      @close="
        showAttemptedError = false;
        showPartBtn = false;
      "
      :showHeader="false"
      :showCloseBtn="false"
    >
      <template v-slot:body>
        <div class="attempted-err relative">
          <p
            @click="
              showAttemptedError = false;
              showPartBtn = false;
            "
            style="position: absolute; right: 12px; cursor: pointer; top: 0"
          >
            <span class="material-icons earn-close">close</span>
          </p>

          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <div class="flex flex-center text-center">
            <span
              class="mesage-body fs-16 fw-600 ln-17"
              style="margin-bottom: 25px; white-space: pre-line; width: 424px"
            >
              {{ errMsg }}
            </span>
          </div>
          <button class="cancel-button fs-13 fw-600 ln-18" @click="showAttemptedError = false">
            Ok
          </button>
          <!-- <div v-if="showPartBtn" class="part-btn-grp w-100">
                    <button class="part-btn" v-for="(item, index) in testDetails.questions" :key="index" @click="goToPart(index)">Part {{ index + 1 }}</button>
                  </div> -->
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SWorker from "simple-web-worker";
// import {
//   TestTimer,
// } from "../worker-api";
import API from "../Api";
import Mobileapi from "../Mobileapi";
import Modal2 from "../components/Model2.vue";
import Modal from "../components/Modal.vue";

export default {
  data() {
    return {
      // for latex
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        jax: ["input/TeX", "output/HTML-CSS"],
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: true,
          processEnvironments: true,
        },
        "HTML-CSS": {
          fonts: ["TeX"],
        },
      },
      optionsForMTF: ["A", "B", "C", "D"],
      // for latex ends here
      showModalPopUp: false,
      images: [],
      fileList: [],
      fileLsit: "",
      clearAnswerModal: false,
      clientHeight: document.documentElement.clientHeight,
      testUserId: null,
      testId: null,
      baseUrl: null,
      type: null,
      startTime: moment(),
      toggleModal: false,
      subjectiveToggleModal: false,
      leavePageModal: false,
      leaveTo: null,
      timeLeft: 0,
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timer: null,
      toggleSidePanel: false,

      testDetails: null,
      currentQuestionIndex: 0,
      currentPartIndex: 0,
      ssTestKey: "",
      instituteTestId: null,
      SLtoken: "",
      InstTestQuestionSubmitLater: false,
      InstituteTestSubTypeQuestion: true,
      //   SolutionImageUploaded: [],
      SolutionUploadImage: [],
      ViewSubTypeQuestion: false,
      SubjectiveQuestionImage: "",
      SubjectiveQuestionList: [],
      files: [],
      questionList: [],
      QuestionFile: [],
      QuestionIdList: [],
      subjectiveQuesList: [],
      questionSolutionList: [],
      fileListFinal: [],
      SelectedQuestionId: null,
      SubjectiveTypeQuestionIndex: "",
      serverTime: "",
      SubmittedInstituteSubjective: [],
      Base64File: null,
      QuestionIndex: null,
      SelectedImageName: "",
      InstituteEndTest: true,
      InstituteSubjectiveTypeEndTest: false,
      SubjectiveQuestionTypeId: null,
      SubjectiveQuestionIndexLength: null,
      SwitchToNextPart: false,
      endtest: true,
      InstituteTestCurrentQuestionIndex: null,
      InstituteTestCurrentPartIndex: null,
      SubjectId: null,
      currentsubjectiveQuestionId: "",
      timer1: null,
      showAllQuestionDone: false,
      attemptedQnsDetail: [],
      showAttemptedError: false,
      isSubmitTrigger: false,
      errMsg: "",
      showPartBtn: false,
      totalAttemptedQns: 0,
      attemptedQns: 0,
      matchTheFollowingAnswers: [],
      isQnsAttempted: false,
      countSubmitedSubjectiveAnswer: 0,
      secnondTimer: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.leaveTo) {
      next();
    } else {
      this.leaveTo = to;
      this.leavePageModal = true;
      next(false);
    }
  },
  activated() {
    if (localStorage && localStorage.getItem("SLToken")) {
      this.SLtoken = localStorage.getItem("SLToken") || "";
    }
    this.testUserId = parseInt(this.$route.params.testUserId);
    this.type = parseInt(this.$route.params.testType);
    this.instituteTestId = parseInt(this.$route.params.InstituteTestId);
    this.ssTestKey = `${this.testUserId}-${this.type}`;
    this.baseUrl = this.user.DomainName;
    Mobileapi.getServerTime({}, (resp) => {
      this.serverTime = resp.data.datetime;
      this.serverTime = moment(this.serverTime).add(1, "seconds");
      this.startTime = moment(this.serverTime);
      this.startServerTimer();
      if (this.type === 2) {
        this.$store.dispatch("showLoader", true);
        API.startTest(
          {
            testUserId: this.testUserId,
            type: 2,
          },
          () => {
            this.getTest();
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      } else {
        this.getTest();
      }
    });
    const user = this.$store.getters.user;
    Mobileapi.GetInstituteTestSubjectiveQuestions(this.testUserId, user.UserId, (rep) => {
      this.SubjectiveQuestionList = rep.data;
      for (let i = 0; i < this.SubjectiveQuestionList.length; i += 1) {
        this.SolutionUploadImage.push(false);
      }
    });
    this.InstituteEndTest = true;
    this.InstituteSubjectiveTypeEndTest = false;
    this.SwitchToNextPart = false;
    this.endtest = true;
    this.triggerTimer();
  },
  deactivated() {
    clearTimeout(this.timer);
    this.testDetails = null;
    this.leavePageModal = false;
    this.leaveTo = null;
  },
  methods: {
    clearAnswer() {
      this.clearAnswerModal = false;
      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        serialNumber: this.currentQuestion.SerialNumber,
        partSerialNumber: this.currentQuestion.PartSerialNumber,
        testUserId: this.currentQuestion.TestUserId,
        answerOption: this.currentQuestion.Answer.toString(),
        startedOn: this.currentQuestion.StartedOn,
        review: 3,
      };
      this.$store.dispatch("showLoader", true);

      API.submitAnswer(
        data,
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.errors.length) {
            window.M.toast({
              html: response.errors[0],
            });
          } else {
            this.attemptedQnsDetail = this.attemptedQnsDetail.map((item, index) => {
              if (index === this.currentPartIndex) {
                return { ...item, QnsAttempted: item.QnsAttempted - 1 };
              }
              return item;
            });
            this.attemptedQns = this.attemptedQnsDetail.reduce(
              (accu, curr) => accu + Number(curr.QnsAttempted),
              0,
            );
            this.currentQuestion.Answer = "";
            this.currentQuestion.IsAttempted = 0;
            this.saveToSS();
            this.serverTime = response.date[0];
            this.serverTime = moment(this.serverTime).add(1, "seconds");
            this.startTime = moment(this.serverTime);
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    getTest() {
      this.$store.dispatch("showLoader", true);
      API.getTest(
        {
          testUserId: this.testUserId,
          type: this.type,
        },
        (response) => {
          this.testDetails = response;
          let qnsIndex = 1;
          this.testDetails.questions.forEach((qnslist) => {
            qnslist.forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              element.questionIndex = qnsIndex;
              qnsIndex += 1;
            });
          });
          if (Object.keys(this.testDetails.questions).length > 0) {
            this.testDetails.questions.forEach((item1) => {
              this.attemptedQnsDetail.push({
                QnsAttempted: item1.reduce(
                  (accu, currVal) => accu + Number(currVal.IsAttempted),
                  0,
                ),
                AllowedAttempts: item1[0].AllowedAttempts,
                TotalQuestions: item1.length,
              });
              this.totalAttemptedQns += item1[0].AllowedAttempts;
            });
            this.attemptedQns = this.attemptedQnsDetail.reduce(
              (accu, curr) => accu + Number(curr.QnsAttempted),
              0,
            );
          }
          if (this.currentQuestion.QuestionTypeId === 2) {
            this.SubjectId =
              this.testDetails.questions[this.currentPartIndex][
                this.currentQuestionIndex
              ].SubjectId;
          }
          if (
            this.testDetails.questions[this.currentPartIndex].length <
              this.testDetails.test.TotalQuestions &&
            this.currentQuestion.QuestionTypeId === 2
          ) {
            this.SwitchToNextPart = true;
            this.endtest = false;
          } else {
            this.SwitchToNextPart = false;
            this.endtest = true;
          }
          if (this.testDetails.status === "Completed") {
            window.M.toast({
              html: "Test was already ended",
            });
            setTimeout(() => {
              window.location.href = `${this.baseUrl}/SelectTestHistory.aspx`;
            }, 2000);
          } else {
            this.$store.dispatch("showLoader", false);
          }
          if (window.localStorage[this.ssTestKey]) {
            const td = JSON.parse(window.localStorage[this.ssTestKey]);
            this.testDetails.questions = td.questions;
          }
          this.currentQuestionIndex = parseInt(window.localStorage[`${this.ssTestKey}-cqi`]) || 0;
          this.currentPartIndex = parseInt(window.localStorage[`${this.ssTestKey}-cpi`]) || 0;
          this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");

          localStorage[`${this.ssTestKey}-et`] = localStorage[`${this.ssTestKey}-et`]
            ? localStorage[`${this.ssTestKey}-et`]
            : this.serverTime + this.testDetails.test.DurationAvailableInMins * 60 * 1000;
          this.timeLeft = Math.ceil(
            (localStorage[`${this.ssTestKey}-et`] - this.serverTime) / 1000,
          );
          this.startTimer();
          // }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    ViewSubQuestion(QuestionImg) {
      this.SubjectiveQuestionImage = QuestionImg;
      this.ViewSubTypeQuestion = true;
    },
    replaceByFirstCharacter(arr, char, newStr) {
      return arr.map((e) => (e[0] === char ? newStr : e));
    },
    selectAnswerOption(option) {
      if (this.currentQuestion.QuestionTypeId === 1 || this.currentQuestion.QuestionTypeId === 5) {
        this.currentQuestion.Answer = option;
      }
      if (this.currentQuestion.QuestionTypeId === 7) {
        const replacedArray = this.replaceByFirstCharacter(
          this.matchTheFollowingAnswers,
          option.charAt(0),
          option,
        );
        this.matchTheFollowingAnswers = replacedArray.includes(option)
          ? replacedArray
          : [...this.matchTheFollowingAnswers, option];
        this.currentQuestion.Answer = this.matchTheFollowingAnswers.toString();
      }
      if (this.currentQuestion.QuestionTypeId === 3) {
        if (this.currentQuestion.Answer.includes(option)) {
          this.currentQuestion.Answer = this.currentQuestion.Answer.replace(option, "");
        } else {
          this.currentQuestion.Answer = `${this.currentQuestion.Answer}${option}`;
        }
        this.currentQuestion.Answer = this.currentQuestion.Answer.split("")
          .sort((a, b) => a.localeCompare(b))
          .join("");
      }

      if (this.currentQuestion.IsMarkedForReview) {
        this.currentQuestion.ReviewAnswerOption = this.currentQuestion.Answer;
      }
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.timeLeft = Math.ceil((localStorage[`${this.ssTestKey}-et`] - this.serverTime) / 1000);
        if (this.timeLeft > 0) {
          this.timeLeft -= 1;
          this.time.hours = Math.floor(this.timeLeft / 3600);
          this.time.minutes = Math.floor((this.timeLeft - this.time.hours * 3600) / 60);
          this.time.seconds = Math.floor(
            this.timeLeft - this.time.hours * 3600 - this.time.minutes * 60,
          );
          this.startTimer();
        } else if (this.testDetails.status !== "Completed") {
          window.M.toast({
            html: "Time completed. Test will be submitted.",
          });
          this.endTest();
        }
      }, 1000);
    },
    triggerTimer() {
      this.secnondTimer = setTimeout(() => {
        Mobileapi.getServerTime({}, (resp) => {
          this.serverTime = resp.data.datetime;
          this.serverTime = moment(this.serverTime).add(1, "seconds");
          this.startTime = moment(this.serverTime);
        });
        this.triggerTimer();
      }, 30000);
    },
    startServerTimer() {
      SWorker.run((arg) => `Hello, ${arg}!`, ["World"])
        .then((result) => {
          console.log(result);
          this.timer1 = window.setInterval(() => {
            this.serverTime = moment(this.serverTime).add(1, "seconds");
          }, 1000);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    saveToSS() {
      window.localStorage[`${this.ssTestKey}-cpi`] = this.currentPartIndex;
      window.localStorage[`${this.ssTestKey}-cqi`] = this.currentQuestionIndex;
    },
    saveForLater() {
      this.isSubmitTrigger = false;
      this.currentQuestion.IsMarkedForReview = 1;
      this.currentQuestion.ReviewAnswerOption = this.currentQuestion.Answer;
      this.saveToSS();
      this.submitAnswer(1);
    },
    InstituteTestsaveForLater() {
      this.currentQuestion.IsMarkedForReview = 1;
      this.currentQuestion.ReviewAnswerOption = this.currentQuestion.Answer;
      this.saveToSS();
      this.submitAnswer(1);
    },
    prevQuestion() {
      if (this.currentQuestion.IsAttempted !== 1 && this.currentQuestion.IsMarkedForReview !== 1) {
        this.currentQuestion.Answer = "";
      }
      this.isSubmitTrigger = false;
      this.submitAnswer(2, () => {
        if (this.currentQuestionIndex === 0) {
          this.currentPartIndex -= 1;
          this.currentQuestionIndex = this.testDetails.questions[this.currentPartIndex].length - 1;
        } else {
          this.currentQuestionIndex -= 1;
        }
        this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
        this.saveToSS();
      });
    },
    nextQuestion() {
      if (this.currentQuestion.IsAttempted !== 1 && this.currentQuestion.IsMarkedForReview !== 1) {
        this.currentQuestion.Answer = "";
      }
      this.submitAnswer(2, () => {
        if (
          this.currentQuestionIndex <
          this.testDetails.questions[this.currentPartIndex].length - 1
        ) {
          this.currentQuestionIndex += 1;
          this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
          this.saveToSS();
        } else if (
          this.currentQuestionIndex ===
            this.testDetails.questions[this.currentPartIndex].length - 1 &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.currentQuestionIndex = 0;
          this.currentPartIndex += 1;
          this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
          this.saveToSS();
        }
      });
    },
    nextFinalQuestion() {
      this.submitFinalAnswer(2, () => {
        if (
          this.currentQuestionIndex <
          this.testDetails.questions[this.currentPartIndex].length - 1
        ) {
          this.currentQuestionIndex += 1;
          this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
          this.saveToSS();
        } else if (
          this.currentQuestionIndex ===
            this.testDetails.questions[this.currentPartIndex].length - 1 &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.currentQuestionIndex = 0;
          this.currentPartIndex += 1;
          this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
          this.saveToSS();
        }
      });
    },
    SubjectiveAttempted() {
      // this.currentQuestion.IsMarkedForReview = 0;
      // this.currentQuestion.IsAttempted = 1;
      this.InstitutenextQuestion();
      this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
      this.saveToSS();
    },
    InstitutenextQuestion() {
      if (
        this.currentQuestionIndex <
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.currentQuestionIndex += 1;
        this.currentQuestion.StartedOn = moment().format("YYYY-MM-DD HH:mm:ssZ");
        this.saveToSS();
      } else if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.UploadNowSubjectQuestion();
        window.M.toast({
          html: "No more questions left to answer. Test will be submitted.",
        });
        if (
          this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.UploadNowSubjectQuestion();
        }
      } else if (this.submittedCount === this.testDetails.test.TotalQuestions) {
        this.UploadNowSubjectQuestion();
      }
      this.InstituteTestCurrentQuestionIndex = this.currentQuestionIndex;
      this.InstituteTestCurrentPartIndex = this.currentQuestion.QuestionTypeId;
      this.SubjectId = this.currentQuestion.SubjectId;
      this.currentQuestionIndex = this.InstituteTestCurrentQuestionIndex;
      if (this.InstTestQuestionSubmitLater === true) {
        this.currentsubjectiveQuestionId =
          this.testDetails.questions[this.currentPartIndex].length - 1;
      }
      if (this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length) {
        this.UploadNowSubjectQuestion();
        window.M.toast({
          html: "No more questions left to answer. Test will be submitted.",
        });
        if (
          this.currentQuestionIndex === this.testDetails.questions[this.currentPartIndex].length &&
          this.currentPartIndex < this.testDetails.questions.length - 1
        ) {
          this.UploadNowSubjectQuestion();
        }
        // }
      } else if (this.submittedCount === this.testDetails.test.TotalQuestions) {
        this.UploadNowSubjectQuestion();
      }
    },
    gotoQuestion(index, index2) {
      this.currentPartIndex = index;
      this.currentQuestionIndex = index2;
      this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
      this.toggleSidePanel = !this.toggleSidePanel;
      this.saveToSS();
      // if (this.InstituteTestCurrentPartIndex === 2 && (this.currentPartIndex < this.testDetails.questions.length - 1)) {
      //   this.currentQuestionIndex = this.InstituteTestCurrentQuestionIndex;
      // } else {
      //   this.currentQuestionIndex = index2;
      // }
    },
    submitAnswer(review, cb) {
      if (!review && this.currentQuestion.Answer === "") {
        window.M.toast({
          html: "Please select an answer before submission",
        });
        return;
      }
      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        serialNumber: this.currentQuestion.SerialNumber,
        partSerialNumber: this.currentQuestion.PartSerialNumber,
        testUserId: this.currentQuestion.TestUserId,
        answerOption: this.currentQuestion.Answer,
        startedOn: this.currentQuestion.StartedOn,
        review,
      };
      this.$store.dispatch("showLoader", true);
      API.submitAnswer(
        data,
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.errors.length) {
            window.M.toast({
              html: response.errors[0],
            });
          } else {
            this.serverTime = response.date[0];
            this.serverTime = moment(this.serverTime).add(1, "seconds");
            this.startTime = moment(this.serverTime);

            if (!review) {
              this.currentQuestion.IsMarkedForReview = 0;
              this.currentQuestion.IsAttempted = 1;
              this.nextQuestion();
              if (this.submittedCount === this.testDetails.test.TotalQuestions) {
                this.showAllQuestionDone = true;
                // this.endTest();
              }
              // if (this.SubjectiveQuestionTypeId === 2) {
              //   if (this.submittedCount === this.testDetails.test.TotalQuestions - 1) {
              //     this.endTest();
              //   }
              // }
            }
            this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
            this.saveToSS();
            if (cb) {
              cb();
            }
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    submitFinalAnswer(review, cb) {
      if (!review && this.currentQuestion.Answer === "") {
        window.M.toast({
          html: "Please select an answer before submission",
        });
        return;
      }

      if (this.currentQuestion.IsAttempted === 1 && review === 0) {
        this.isQnsAttempted = true;
      } else if (this.currentQuestion.IsAttempted !== 1 && review === 0) {
        this.isQnsAttempted = false;
      }
      if (
        this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
        this.currentQuestion.IsAttempted !== 1
      ) {
        this.showAttemptedError = true;
        this.errMsg =
          "You have completed all required questions, Please clear answer of any question to submit this answer.";
        return;
      }

      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        serialNumber: this.currentQuestion.SerialNumber,
        partSerialNumber: this.currentQuestion.PartSerialNumber,
        testUserId: this.currentQuestion.TestUserId,
        answerOption: this.currentQuestion.Answer.toString(),
        startedOn: this.currentQuestion.StartedOn,
        review,
      };
      this.$store.dispatch("showLoader", true);
      API.submitAnswer(
        data,
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.errors.length) {
            window.M.toast({
              html: response.errors[0],
            });
          } else {
            this.serverTime = response.date[0];
            this.serverTime = moment(this.serverTime).add(1, "seconds");
            this.startTime = moment(this.serverTime);

            if (!review) {
              this.currentQuestion.IsMarkedForReview = 0;

              if (
                this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted !==
                  this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
                this.isSubmitTrigger &&
                this.currentQuestion.IsAttempted !== 1 &&
                review === 0
              ) {
                this.currentQuestion.IsAttempted = 1;
                this.attemptedQnsDetail = this.attemptedQnsDetail.map((item, index) => {
                  if (index === this.currentPartIndex) {
                    return { ...item, QnsAttempted: item.QnsAttempted + 1 };
                  }
                  return item;
                });
                this.attemptedQns = this.attemptedQnsDetail.reduce(
                  (accu, curr) => accu + Number(curr.QnsAttempted),
                  0,
                );
              }

              if (
                this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
                  this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
                this.isSubmitTrigger &&
                this.currentQuestion.IsAttempted === 1 &&
                this.testDetails.questions[this.currentPartIndex][0].AllowedAttempts !==
                  this.testDetails.questions[this.currentPartIndex].length &&
                !this.isQnsAttempted
              ) {
                if (this.testDetails.questions.length > 1) {
                  this.showAttemptedError = true;
                  if (Number(this.totalAttemptedQns) - Number(this.attemptedQns) === 0) {
                    this.errMsg =
                      "You have completed all the questions in this test. You may either revise or end the test.";
                  } else {
                    this.errMsg =
                      "You have completed all required questions of this part, You can move to another part.";
                  }
                } else if (
                  this.testDetails.questions.length === 1 &&
                  this.attemptedQnsDetail[this.currentPartIndex].TotalQuestions !==
                    this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts
                ) {
                  this.showAttemptedError = true;
                  this.errMsg =
                    "You have completed all the questions in this test. You may either revise or end the test.";
                }
                this.isSubmitTrigger = false;
              } else if (
                this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted ===
                  this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts &&
                this.isSubmitTrigger &&
                this.currentQuestion.IsAttempted !== 1
              ) {
                this.showAttemptedError = true;
                this.errMsg =
                  "You have completed all required questions, Please clear answer of any question to submit this answer.";
                this.isSubmitTrigger = false;
              }
              this.nextFinalQuestion();
              if (this.submittedCount === this.testDetails.test.TotalQuestions) {
                this.showAllQuestionDone = true;
                // this.endTest();
              }
            }

            this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
            this.saveToSS();
            if (cb) {
              cb();
            }
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    goToPart(partIndex) {
      this.showPartBtn = false;
      this.isSubmitTrigger = false;
      this.showAttemptedError = false;
      this.currentPartIndex = partIndex;
      const currentPart = this.testDetails.questions[this.currentPartIndex];
      for (let i = 0; i < currentPart.length; i += 1) {
        if (currentPart[i].IsAttempted === 0) {
          this.currentQuestionIndex = i;
          break;
        }
      }
      this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
      this.saveToSS();
    },
    endTest() {
      clearTimeout(this.timer);
      const data = {
        testId: this.testDetails.test.Id,
        type: this.type,
        testUserId: this.currentQuestion.TestUserId,
      };
      this.$store.dispatch("showLoader", true);
      API.endTest(
        data,
        (response) => {
          if (response.errors.length) {
            this.$store.dispatch("showLoader", false);
            window.M.toast({
              html: response.errors[0],
            });
          } else if (this.leaveTo) {
            window.M.toast({
              html: "Test Submitted Successfully",
            });
            this.$router.push(this.leaveTo);
          } else {
            window.M.toast({
              html: "Test Submitted Successfully",
            });
            window.location.href = `/TestReport/${this.currentQuestion.TestUserId}/${this.type}?feedback=true`;
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    UploadNowSubjectQuestion() {
      this.InstituteEndTest = false;
      this.InstituteSubjectiveTypeEndTest = true;
      if (
        this.currentQuestionIndex ===
          this.testDetails.questions[this.currentPartIndex].length - 1 &&
        this.currentPartIndex < this.testDetails.questions.length - 1
      ) {
        if (this.SubjectiveQuestionList.length === 0) {
          this.currentQuestionIndex = 0;
          this.currentPartIndex += 1;
          this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
          this.saveToSS();
          if (this.currentQuestion.QuestionTypeId !== 2) {
            this.InstTestQuestionSubmitLater = true;
            this.InstituteTestSubTypeQuestion = false;
          }
        } else {
          this.InstTestQuestionSubmitLater = true;
          this.InstituteTestSubTypeQuestion = false;
        }
      }
      if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        if (this.SubjectiveQuestionList.length === 0) {
          this.endTest();
          window.M.toast({
            html: "Test Submitted Successfully",
          });
          window.location.href = `/TestReport/${this.currentQuestion.TestUserId}/${this.type}`;
        } else {
          this.InstTestQuestionSubmitLater = true;
          this.InstituteTestSubTypeQuestion = false;
          //   this.UploadNowSubjectQuestion();
        }
      }
    },
    SubjectiveQuestionImageId(index, QuestionId) {
      this.SelectedQuestionId = QuestionId;
      this.QuestionIndex = index;
    },
    base64(event) {
      this.showModalPopUp = false;
      this.SelectedImageName = "";
      this.fileListFinal = [];
      const fileDataTemp = [];
      if (
        this.attemptedQnsDetail[this.currentPartIndex].QnsAttempted !==
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts ||
        this.SolutionUploadImage[this.QuestionIndex]
      ) {
        for (let i = 0; i < event.target.files.length; i += 1) {
          const reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          if (event.target.files[i].size > 3000000) {
            this.showModalPopUp = true;
            // eslint-disable-next-line no-const-assign
            this.fileListFinal = [];
            break;
          }
          if (event.target.files.length > 3) {
            window.M.toast({
              html: "You are only allowed to upload a maximum of 3 files at a time",
            });
            break;
          } else {
            reader.onloadend = () => {
              this.$store.dispatch("showLoader", true);
              this.Base64File = reader.result.split(",")[1];
              // this.Base64File.replace("dataapplication/pdfbase64", "");
              const fileData = {
                fileData: this.Base64File,
                fileName: event.target.files[i].name,
              };
              fileDataTemp.push(fileData);
              if (fileDataTemp.length > 0) {
                this.$store.dispatch("showLoader", false);
              }
            };
            if (!this.SolutionUploadImage[this.QuestionIndex]) {
              // this.currentQuestion.IsAttempted = 1;
              this.attemptedQnsDetail = this.attemptedQnsDetail.map((item, index) => {
                if (index === this.currentPartIndex) {
                  return { ...item, QnsAttempted: item.QnsAttempted + 1 };
                }
                return item;
              });
              this.attemptedQns = this.attemptedQnsDetail.reduce(
                (accu, curr) => accu + Number(curr.QnsAttempted),
                0,
              );
              this.testDetails.questions.forEach((item1, index1) =>
                item1.forEach((item2, index2) => {
                  if (this.QuestionIndex === index2 && index1 === this.currentPartIndex) {
                    this.testDetails.questions[index1][index2].IsAttempted = 1;
                  }
                }),
              );
            }
          }
          this.fileListFinal = fileDataTemp;
          this.SelectedImageName = event.target.files[i].name;
        }
        if (this.SelectedImageName.length > 0) {
          this.SolutionUploadImage[this.QuestionIndex] = true;
          this.QuestionIndex = null;
        }
        //   }
        const questionData = {
          questionId: this.SelectedQuestionId,
          maximumMarks: 3,
          files: this.fileListFinal,
        };
        this.subjectiveQuesList.push(questionData);
      } else if (this.testDetails.questions.length > 1) {
        this.showAttemptedError = true;
        this.errMsg =
          "You have completed all required questions of this part, You can move to another part.";
        // this.showPartBtn = true;
      } else if (
        this.testDetails.questions.length === 1 &&
        this.attemptedQnsDetail[this.currentPartIndex].TotalQuestions !==
          this.attemptedQnsDetail[this.currentPartIndex].AllowedAttempts
      ) {
        this.showAttemptedError = true;
        this.errMsg =
          "You have completed all the questions in this test. You may either revise or end the test.";
      }
    },
    InstituteTestSubjectQuestion() {
      this.countSubmitedSubjectiveAnswer = this.SolutionUploadImage.reduce(
        (acc, val) => (val === true ? acc + 1 : acc),
        0,
      );
      if (
        this.countSubmitedSubjectiveAnswer !==
        this.testDetails.questions[this.currentPartIndex][0].AllowedAttempts
      ) {
        this.subjectiveToggleModal = true;
        return;
      }

      if (!this.subjectiveToggleModal) {
        this.$store.dispatch("showLoader", true);
        const user = this.$store.getters.user;
        const data = {
          instituteTestId: this.testUserId,
          affiliationId: Number(user.AffiliationId),
          batchId: Number(user.CenterCodeId),
          courseId: String(user.CourseId),
          subjectId: Number(this.SubjectId),
          studentId: Number(user.UserId),
          questionList: this.subjectiveQuesList,
        };
        Mobileapi.InstituteTestSubjectiveQuestionListSubmit(data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.SubmittedInstituteSubjective = response;
          this.SubjectiveQuestionTypeId =
            this.testDetails.questions[this.currentPartIndex][
              this.currentQuestionIndex
            ].QuestionTypeId;
          this.SubjectiveQuestionIndexLength =
            this.testDetails.questions[this.currentPartIndex][this.currentQuestionIndex].length;
          if (response.responseCode === 200) {
            if (
              this.currentQuestionIndex ===
                this.testDetails.questions[this.currentPartIndex].length - 1 &&
              this.currentPartIndex < this.testDetails.questions.length - 1
            ) {
              this.currentQuestionIndex = 0;
              this.currentPartIndex += 1;
              this.currentQuestion.StartedOn = moment(this.serverTime).format(
                "YYYY-MM-DD HH:mm:ssZ",
              );
              this.saveToSS();
              if (this.currentQuestion.QuestionTypeId !== 2) {
                this.InstTestQuestionSubmitLater = true;
                this.InstituteTestSubTypeQuestion = false;
              }
            } else if (Number(this.totalAttemptedQns) - Number(this.attemptedQns) === 0) {
              this.endTest();
              this.SwitchToNextPart = false;
              this.endtest = true;
            }
          }
        });
      }
    },
    endSubjectiveTest() {
      if (
        this.currentsubjectiveQuestionId ===
          this.testDetails.questions[this.currentPartIndex].length - 1 &&
        this.currentPartIndex < this.testDetails.questions.length - 1
      ) {
        this.currentQuestionIndex = 0;
        this.currentPartIndex += 1;
        this.currentQuestion.StartedOn = moment(this.serverTime).format("YYYY-MM-DD HH:mm:ssZ");
        this.saveToSS();
        this.subjectiveToggleModal = false;
        if (this.currentQuestion.QuestionTypeId !== 2) {
          this.InstTestQuestionSubmitLater = true;
          this.InstituteTestSubTypeQuestion = false;
          this.InstituteEndTest = true;
          this.InstituteSubjectiveTypeEndTest = false;
        }
      } else if (
        this.currentQuestionIndex ===
        this.testDetails.questions[this.currentPartIndex].length - 1
      ) {
        this.endTest();
        this.InstituteEndTest = true;
        this.InstituteSubjectiveTypeEndTest = false;
      }
      for (let i = 0; i < this.SubjectiveQuestionList.length; i += 1) {
        if (this.SolutionUploadImage[i] === true) {
          this.$store.dispatch("showLoader", true);
          const user = this.$store.getters.user;
          const data = {
            instituteTestId: this.testUserId,
            affiliationId: Number(user.AffiliationId),
            batchId: Number(user.CenterCodeId),
            courseId: Number(user.CourseId),
            subjectId: Number(this.SubjectId),
            studentId: Number(user.UserId),
            questionList: this.subjectiveQuesList,
          };
          Mobileapi.InstituteTestSubjectiveQuestionListSubmit(data, (response) => {
            this.$store.dispatch("showLoader", false);
            this.SubmittedInstituteSubjective = response;
            if (response.responseCode === 200) {
              if (
                this.currentQuestionIndex ===
                  this.testDetails.questions[this.currentPartIndex].length - 1 &&
                this.currentPartIndex < this.testDetails.questions.length - 1
              ) {
                this.currentQuestionIndex = 0;
                this.currentPartIndex += 1;
                this.currentQuestion.StartedOn = moment(this.serverTime).format(
                  "YYYY-MM-DD HH:mm:ssZ",
                );
                this.saveToSS();
                if (this.currentQuestion.QuestionTypeId !== 2) {
                  this.InstTestQuestionSubmitLater = true;
                  this.InstituteTestSubTypeQuestion = false;
                }
              }
            }
          });
          break;
        }
      }
    },
  },
  computed: {
    storage_Url() {
      return process.env.VUE_APP_SL_URL;
    },
    isQuestionOptionVisible() {
      return (
        (this.currentQuestion.Q_LATEX_Status !== "Y" &&
          !this.currentQuestion.Q_LATEX &&
          this.currentQuestion.QuestionTypeId !== 4 &&
          this.currentQuestion.QuestionTypeId !== 7) ||
        (!this.currentQuestion.AnswerOption_A &&
          !this.currentQuestion.AnswerOption_B &&
          !this.currentQuestion.AnswerOption_C &&
          !this.currentQuestion.AnswerOption_D &&
          this.currentQuestion.QuestionTypeId !== 4 &&
          this.currentQuestion.QuestionTypeId !== 7)
      );
    },
    currentQuestion() {
      return this.testDetails.questions[this.currentPartIndex][this.currentQuestionIndex];
    },
    savedForLaterCount() {
      let count = 0;
      this.testDetails.questions.forEach((parts) => {
        count += parts.filter((q) => q.IsMarkedForReview && !q.IsAttempted).length;
      });
      return count;
    },
    submittedCount() {
      let count = 0;
      this.testDetails.questions.forEach((parts) => {
        count += parts.filter((q) => q.IsAttempted).length;
      });
      return count;
    },
    ...mapGetters(["user"]),
  },
  components: {
    Modal,
    Modal2,
  },
};
</script>

<style scoped>
.page-content {
  padding: 16px;
}

.rotate {
  background: url("../assets/landscape.png");
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.portrait {
  display: none;
}

@media screen and (orientation: portrait) {
  .card {
    display: none;
  }

  .portrait {
    display: block;
    background: #3a322f;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1001;
    text-align: center;
    color: #ffffffe6;
    left: 0;
    padding: 0 30px;
  }
}

.card .card-action button {
  margin-right: 24px;
}

.card .card-action button:last-child {
  margin-right: 0;
}

.card-image.question {
  min-height: 250px;
  padding: 25px;
}

.timerRed {
  background-color: red;
  color: #fff;
  padding: 2px 4px;
}

.cursor {
  cursor: pointer;
}

.side-panel {
  background: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 19%);
  position: fixed;
  left: 100%;
  top: 60px;
  width: 350px;
  transition: all 0.5s;
  z-index: 99;
}

.active {
  left: calc(100% - 350px);
}

.side-panel-button {
  background: #3d3d3d;
  font-size: 0.75rem;
  text-transform: uppercase;
  padding: 18px 30px;
  color: #fff;
  font-weight: 700;
  border-radius: 80px 20px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 195px;
  text-align: center;
  left: -122px;
  top: 25%;
  transform: rotate(-90deg);
  cursor: pointer;
}

.side-panel-content {
  overflow: auto;
  padding: 6px 32px;
}

.stat-btn {
  background-color: #eff0f0;
  border-radius: 30px;
  padding: 14px;
  margin-bottom: 15px;
  text-align: center;
  width: 80%;
  font-size: 1.2rem;
}

.stat-btn span {
  float: left;
  color: #fff;
  height: 53px;
  width: 53px;
  margin: -14px;
  border-radius: 50%;
  border: 1px solid #0000;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.stat-btn span.submitted {
  background-color: #6a2f85;
}

.stat-btn span.saved {
  background-color: #ee7800;
}

.stat-btn span.notattempted {
  border: 1px solid #aaa;
  color: #000;
}

.btn-answeroption {
  position: relative;
}

.btn-navigation,
.btn-answeroption {
  background-color: #0000;
  border: 1px solid #555;
  color: #000;
  text-align: center;
  font-weight: 500;
  height: 44px;
  width: 50px;
  font-size: 1.5rem;
}

.btn-answeroption i {
  position: absolute;
  left: 2px;
  top: -9px;
  font-size: 0.95rem;
}

.btn-answeroption i.uncheck,
.answer_option i.uncheck {
  display: unset;
}

.btn-answeroption i.check,
.answer_option i.check {
  display: none;
}

.btn-answeroption.blue i.uncheck,
.answer_option.blue i.uncheck {
  display: none;
}

.btn-answeroption.blue i.check,
.answer_option.blue i.check {
  display: unset;
}

.btn-navigation.noborder,
.btn-answeroption.noborder {
  border: 1px solid #0000;
}

.border-none {
  border: none !important;
}

.btn-navigation {
  height: 50px;
  width: 55px;
  font-size: 1.3rem;
  line-height: 50px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.selectedImage {
  /* display: block; */
  display: inline;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: row;
}

.previewImage {
  margin: auto;
}

table thead tr th {
  padding-left: 50px;
}

table tbody tr td {
  padding-left: 50px;
}

table thead tr {
  justify-content: center;
  text-align: center;
}

table thead tr th {
  background-color: #6d2d7e;
  color: white;
  border-radius: 0px;
}

table tbody tr {
  line-height: 30px;
}

.Upload-Later {
  display: flex;
  justify-content: center;
}

.Upload-Later .ChangeProfile .UploadLaterIcon {
  width: 32px;
  height: 32px;
  margin-left: 25%;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.flex {
  display: flex !important;
}

.flex-center {
  justify-content: center;
}

.earn-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.skyblue-text {
  color: #d70000 !important;
}

.fw-700 {
  font-weight: 700;
}

.hw-to-earn-content {
  padding: 35px !important;
}

/* ._hj_feedback_container{
  display: none !important;

}
._hj-zRk2h__Feedback__feedback._hj-z1NGf__Feedback__button {
    position: fixed;
    display: none !important;
} */

@media only screen and (max-width: 800px) {
  .extraBottomSpace {
    padding-bottom: 70px !important;
  }
}

.attempted-err {
  width: 450px;
  max-height: 500px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.cancel-button {
  width: 125px;
  padding-right: 5px;
  padding-left: 5px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff7675;
  cursor: pointer;
}

.part-btn-grp {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.part-btn {
  background: transparent;
  border: 2px solid green;
  padding: 5px 15px;
  border-radius: 4px;
  color: green;
  font-weight: 500;
  cursor: pointer;
}

.answer_option {
  position: relative;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #fff;
  padding: 8px 5px;
  margin-top: 10px;
  display: block;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

/* match the following css  */

.match-the-following-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.match-the-following-item p {
  border-radius: 5px;
  border: 1px solid #111;
  padding: 10px 20px;
  margin: 0;
  background-color: #555;
  color: #fff;
}

.match-the-following-item button {
  border-radius: 5px;
  border: 1px solid #111;
  background-color: #fff;
  padding: 10px 20px;
  margin-right: 5px !important;
}
</style>
